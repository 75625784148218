import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SearchReponseInvoice } from 'src/app/interfaces-search/search-response-invoice';
import { FileType } from 'src/app/interfaces/invoice-file';
import { InvoiceCheckFlag } from 'src/app/interfaces/ocr-metadata';
import { InvoiceDataItem } from '../view-pdf.component';
import { ReviewStatus } from '../../../interfaces/invoice.model';
import { ApiService } from '../../../services/api.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-view-pdf-sidebar-content',
  templateUrl: './view-pdf-sidebar-content.component.html',
  styleUrls: ['./view-pdf-sidebar-content.component.scss'],
})
export class ViewPdfSidebarContentComponent implements OnInit {
  @Input()
  invoice: SearchReponseInvoice;
  @Input()
  invoiceFlags: InvoiceCheckFlag;
  @Input()
  userEmail: string;
  @Input()
  userName: string;

  FileType = FileType;
  invoiceDataTable: MatTableDataSource<InvoiceDataItem>;
  invoiceDataTableValues: InvoiceDataItem[];
  invoiceName: string = '';
  ReviewStatus = ReviewStatus;

  constructor(private api: ApiService, private snackbar: SnackbarService) {}

  ngOnInit(): void {
    if (this.invoiceFlags) {
      this.getMetaData(this.invoiceFlags);
    }
  }

  filterData(event: any) {
    this.invoiceDataTable.filter = this.invoiceName;
  }

  getTableFilter(): (data: InvoiceDataItem, filter: string) => boolean {
    return (data, filter: string): boolean => {
      return data.title.toLowerCase().includes(filter.toLowerCase());
    };
  }

  getMetaData(invoiceFlags: InvoiceCheckFlag) {
    let invoiceDataTableValues = [];
    Object.keys(invoiceFlags).forEach((flag) => {
      const value = invoiceFlags[flag];
      if (value?.final) {
        const final = value.final;
        if (final.bbox && final.score && final.page_number !== null && final.display_name) {
          invoiceDataTableValues.push({
            title: final.display_name,
            value: final.value,
            confidence: final.score,
            bbox: final.bbox,
            pageNumber: final.page_number,
          });
        }
      } else if (value && Array.isArray(value)) {
        value.forEach((item) => {
          if (item && item.bbox && item.score && item.page_number !== null && item.display_name) {
            invoiceDataTableValues.push({
              title: item.display_name,
              value: item.value,
              confidence: item.score,
              bbox: item.bbox,
              pageNumber: item.page_number,
            });
          }
        });
      }
    });

    invoiceDataTableValues = invoiceDataTableValues.sort((a, b) => {
      if (a.pageNumber !== b.pageNumber) {
        return a.pageNumber - b.pageNumber;
      }
      return a.bbox[2] - b.bbox[2];
    });
    this.invoiceDataTableValues = invoiceDataTableValues;
    const filter = this.getTableFilter();
    this.invoiceDataTable = new MatTableDataSource(invoiceDataTableValues);
    setTimeout(() => {
      this.invoiceDataTable.filterPredicate = filter;
    }, 0);
  }
}
