import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Customer } from 'src/app/interfaces/customer';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchResponseCustomer } from 'src/app/interfaces-search/search-response-customer';
import { AuthService } from 'src/app/services/auth.service';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';

interface CustomerWithAccounts extends Customer {
  accounts: number;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  includeArchived: boolean = false;
  loading: boolean = false;
  customersTableData: MatTableDataSource<Customer>;
  displayedColumns: string[] = [
    'customer_name',
    'customer_alias',
    'customer_account_number',
    'customer_address_city',
    'customer_address_state',
    'accounts',
    'actions',
  ];

  filter: SelectOption = {
    name: 'Filter by name',
    slug: 'customer_name',
    options: [],
    value: '',
    allowPartial: true,
    debounceInputs: true,
    type: SelectType.typeAhead,
  };

  get isAdmin() {
    return this.authService.isAdmin;
  }

  constructor(
    private router: Router,
    private api: ApiService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.customersTableData = new MatTableDataSource([]);
    this.initCustomers();
  }

  ngAfterViewInit(): void {
    this.customersTableData.paginator = this.paginator;
    this.customersTableData.sort = this.sort;
    this.customersTableData.filterPredicate = (data, filter: string): boolean => {
      return (
        data.customer_name.toLowerCase().includes(filter) || data.customer_account_number.toLowerCase().includes(filter)
      );
    };
    this.customersTableData.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.customersTableData.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.initCustomers(skip, take);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  changeArchived(includeArchived: boolean): void {
    this.includeArchived = includeArchived;
    this.initCustomers();
  }

  filterData() {
    this.initCustomers();
    /*
    const filterValue = this.filter.value;
    this.providers.filter = filterValue.trim().toLowerCase();
    */
  }

  async initCustomers(skip: number = 0, take: number = 100) {
    try {
      this.loading = true;
      const include_archived = this.includeArchived;
      const queryItems = { skip, take, include_archived };
      if (this.filter.value !== '') {
        queryItems['customer_name'] = this.filter.value;
      }
      const { items = [], total = null } = await this.api
        .sendRequest<BaseResponse<SearchResponseCustomer[]>>('GET', '/customer/', queryItems)
        .toPromise();
      let customers: CustomerWithAccounts[] = items.map((item) => {
        return {
          ...item.Customer,
          accounts: item.accounts,
        };
      });

      if (skip !== 0 && (!customers || customers.length === 0)) {
        return;
      }

      if (total && total !== 0 && total !== null) {
        this.customersTableData.paginator._intl = new CustomMatPaginatorIntl(total);
      }

      const data = this.customersTableData.data;
      this.customersTableData.data = skip > 0 ? data.concat(customers) : customers;
    } catch (err) {
      console.log(err);
      if (this.authService.loginValid) {
        this.snackbar.showError('Error getting customers');
      }
    } finally {
      this.loading = false;
    }
  }

  editCustomer(customer: Customer) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Update customer',
        addressType: 'Billing',
        formItems: [
          {
            slug: 'customer_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: customer.customer_name,
          },
          {
            slug: 'customer_alias',
            name: 'Alias',
            required: false,
            type: AddFormItem.text,
            currentValue: customer.customer_alias,
          },
          {
            slug: 'customer_account_number',
            name: 'Customer Acct. No.',
            required: true,
            type: AddFormItem.text,
            currentValue: customer.customer_account_number,
          },
          {
            slug: 'customer_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              item: customer,
              street_address: 'customer_address_street_address',
              state: 'customer_address_state',
              city: 'customer_address_city',
              zip: 'customer_address_zip',
            },
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.putCustomer(result.data, customer);
      }
    });
  }

  addCustomer() {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Add new customer',
        formItems: [
          {
            slug: 'customer_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: 'customer_alias',
            name: 'Alias',
            required: false,
            type: AddFormItem.text,
          },
          {
            slug: 'customer_account_number',
            name: 'Customer Acct. No.',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: 'customer_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              street_address: 'customer_address_street_address',
              state: 'customer_address_state',
              city: 'customer_address_city',
              zip: 'customer_address_zip',
            },
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.postCustomer(result.data);
      }
    });
  }

  async putCustomer(
    customerData: {
      customer_name: string;
      customer_alias: string;
      customer_acccount_number: string;
      customer_address_street_address: string;
      customer_address_state: string;
      customer_address_city: string;
      customer_address_zip: string;
    },
    customer: Customer
  ) {
    try {
      this.loading = true;
      await this.api.sendRequest('PUT', `/customer/${customer.customer_id}/`, null, customerData).toPromise();
      this.snackbar.showInfo('Customer successfully updated');
      this.initCustomers();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error creating customers');
    } finally {
      this.loading = false;
    }
  }

  async postCustomer(customer: {
    customer_name: string;
    customer_alias: string;
    customer_acccount_number: string;
    customer_address_street_address: string;
    customer_address_state: string;
    customer_address_city: string;
    customer_address_zip: string;
  }) {
    try {
      this.loading = true;
      await this.api.sendRequest('POST', '/customer/', null, customer).toPromise();
      this.snackbar.showInfo('Customer successfully created');
      this.initCustomers();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error creating customers');
    } finally {
      this.loading = false;
    }
  }

  deleteCustomerDialog(customerId: number) {
    const customer = this.customersTableData.data.find((x) => x.customer_id === customerId);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Customer',
        subtitle: customer.customer_name,
        message: `Are you sure you want to delete ${customer.customer_name}?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteCustomer(customerId);
      }
    });
  }

  async deleteCustomer(customerId: number) {
    try {
      this.loading = true;
      await this.api.sendRequest('DELETE', `/customer/${customerId}/`).toPromise();
      this.snackbar.showInfo('Customer deleted');
      this.initCustomers();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error deleting customer');
    } finally {
      this.loading = false;
    }
  }

  reverse(s: string): string {
    return [...s].reverse().join('');
  }

  details(customerId: string) {
    this.router.navigate(['customer-detail', customerId]);
  }
}
