import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { EPASubregion } from 'src/app/interfaces/epa-subregions';
import { SelectOption } from 'src/app/interfaces/select-option';
import { Service, Utility } from 'src/app/interfaces/utility';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UtilityCalculation } from '../../../interfaces/utility_calculation';

@Component({
  selector: 'app-provider-table',
  templateUrl: './provider-table.component.html',
  styleUrls: ['./provider-table.component.scss'],
})
export class ProviderTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() filters: SelectOption[];
  @Input() includeArchived: boolean;
  @Input() readyForProcessing: boolean;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  loadingUtilities: boolean = false;
  loadingSubregions: boolean = false;
  utilityTableSource: MatTableDataSource<Utility>;
  epaSubregions: EPASubregion[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  displayedColumns: string[] = [
    'utility_name',
    'utility_is_third_party',
    'utility_type',
    'utility_address_city',
    'utility_start_date',
    'utility_end_date',
    'actions',
  ];

  get isAdmin() {
    return this.authService.isAdmin;
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.utilityTableSource = new MatTableDataSource();
    this.setEpaSubregions();
    this.getUtilities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUtilities();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.utilityTableSource.paginator = this.paginator;
    this.utilityTableSource.sort = this.sort;
    this.utilityTableSource.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.utilityTableSource.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getUtilities(skip, take);
      }
    });
  }

  emitLoading() {
    this.loading.next(this.loadingSubregions || this.loadingUtilities);
  }

  getLocation(provider: Utility): string {
    return provider.utility_address_city && provider.utility_address_state
      ? `${provider.utility_address_city}, ${provider.utility_address_state}`
      : 'No address on file';
  }

  details(utility: Utility) {
    this.router.navigate(['/invoice-fields', utility.utility_id]);
  }

  async setEpaSubregions(): Promise<void> {
    try {
      this.loadingSubregions = true;
      this.emitLoading();
      const result = await this.api.sendRequest<BaseResponse<EPASubregion[]>>('GET', `/epa_subregion/`).toPromise();
      if (!result || !result.items) {
        throw new Error('Unable to load EPA Subregions');
      }
      this.epaSubregions = result.items;
    } catch (err) {
      this.snackbar.showError('Error retrieving current EPA Subregions');
    } finally {
      this.loadingSubregions = false;
      this.emitLoading();
    }
  }

  async getUtilities(skip: number = 0, take: number = 100) {
    try {
      this.loadingUtilities = true;
      this.emitLoading();
      const queryItems: { [key: string]: any } = {};
      queryItems.skip = skip;
      queryItems.take = take;
      queryItems.include_archived = this.includeArchived;
      queryItems.utility_ready_for_processing = this.readyForProcessing;
      this.filters.forEach((filter) => {
        if (filter.value) {
          queryItems[filter.slug] = filter.value;
        }
      });
      const { items, total = null } = await this.api
        .sendRequest<BaseResponse<Utility[]>>('GET', '/utility/', queryItems)
        .toPromise();
      if (skip !== 0 && (!items || items.length === 0)) {
        return;
      }
      if (total !== 0 && total !== null) {
        this.utilityTableSource.paginator._intl = new CustomMatPaginatorIntl(total);
      }

      if (skip === 0) {
        this.paginator.firstPage();
      }
      const data = this.utilityTableSource.data;
      this.utilityTableSource.data = skip > 0 ? data.concat(items) : items;
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingUtilities = false;
      this.emitLoading();
    }
  }

  async editUtility(utility: Utility) {
    if (this.loadingSubregions || this.loadingUtilities) {
      return;
    }
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Update Utility',
        formItems: [
          {
            slug: 'utility_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: utility.utility_name,
          },
          {
            slug: 'utility_type',
            name: 'Utility Type',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(Service).map((x) => {
              return { value: Service[x], display: Service[x] };
            }),
            currentValue: utility.utility_type,
          },
          {
            slug: 'utility_is_third_party',
            name: 'Distribution Company',
            required: false,
            type: AddFormItem.checkbox,
            currentValue: utility.utility_is_third_party,
          },
          {
            slug: 'utility_start_date',
            name: 'Utility Start Date',
            type: AddFormItem.date,
            currentValue: utility.utility_start_date,
          },
          {
            slug: 'utility_end_date',
            name: 'Utility End Date',
            type: AddFormItem.date,
            currentValue: utility.utility_end_date,
          },
          {
            slug: 'utility_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              item: utility,
              street_address: 'utility_address_street_address',
              state: 'utility_address_state',
              city: 'utility_address_city',
              zip: 'utility_address_zip',
            },
          },
          {
            slug: 'utility_epa_subregion_id_fk',
            name: 'EPA Subregion',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(this.epaSubregions).map((x) => {
              return { value: this.epaSubregions[x].id, display: this.epaSubregions[x].name };
            }),
            currentValue: utility.utility_epa_subregion_id_fk,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.putUtility(result.data, utility);
      }
    });
  }

  async editUtilityCalculation(utility: Utility) {
    let utilityCalculation: UtilityCalculation = {
      created_by: null,
      created_on: null,
      archived_on: null,
      last_updated_on: null,
      last_updated_by: null,
      utility_calculation_id: null,
      utility_id_fk: utility.utility_id,
    };

    let existed = false;

    try {
      const response = await this.api
        .sendRequest<UtilityCalculation>('GET', `/utility/${utility.utility_id}/calculation`)
        .toPromise();

      if (response !== null) {
        utilityCalculation = response;
        existed = true;
      }
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error loading utility calculation');
      return;
    }

    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Update Utility Calculations',
        formItems: [
          {
            slug: 'lea_co2',
            name: 'Annual CO2 equivalent (lb/kWh)',
            required: false,
            type: AddFormItem.number,
            currentValue: utilityCalculation.lea_co2,
          },
          {
            slug: 'coal_percentage',
            name: 'Coal',
            required: false,
            type: AddFormItem.number,
            currentValue: utilityCalculation.coal_percentage,
          },
          {
            slug: 'gas_percentage',
            name: 'Gas',
            required: false,
            type: AddFormItem.number,
            currentValue: utilityCalculation.gas_percentage,
          },
          {
            slug: 'solar_percentage',
            name: 'Solar',
            required: false,
            type: AddFormItem.number,
            currentValue: utilityCalculation.solar_percentage,
          },
          {
            slug: 'wind_percentage',
            name: 'Wind',
            required: false,
            type: AddFormItem.number,
            currentValue: utilityCalculation.wind_percentage,
          },
          {
            slug: 'other_percentage',
            name: 'Other',
            required: false,
            type: AddFormItem.number,
            currentValue: utilityCalculation.other_percentage,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        if (existed) {
          this.putUtilityCalculation(result.data, utilityCalculation);
        } else {
          this.createUtilityCalculation(result.data, utilityCalculation);
        }
      }
    });
  }

  deleteUtilityDialog(utility: Utility) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Utility',
        subtitle: utility.utility_name,
        message: `Are you sure you want to delete ${utility.utility_name}?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteUtility(utility);
      }
    });
  }

  async deleteUtility(utility: Utility) {
    try {
      this.loadingUtilities = true;
      this.emitLoading();
      await this.api.sendRequest('DELETE', `/utility/${utility.utility_id}/`).toPromise();
      this.utilityTableSource.data = this.utilityTableSource.data.filter((x) => x.utility_id !== utility.utility_id);
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error deleting utility');
    } finally {
      this.loadingUtilities = false;
      this.emitLoading();
    }
  }

  async putUtility(
    data: {
      utility_name: string;
      utility_type: string;
      utility_is_third_party: boolean;
      utility_address_street_address: string;
      utility_address_state: string;
      utility_address_city: string;
      utility_address_zip: string;
      utility_start_date: string;
      utility_end_date: string;
      utility_epa_subregion_id_fk: number | string;
    },
    utility: Utility
  ) {
    try {
      this.loadingUtilities = true;
      this.emitLoading();
      if (data.utility_epa_subregion_id_fk === '' || data.utility_epa_subregion_id_fk === null) {
        delete data.utility_epa_subregion_id_fk;
      }
      const updateUtil = { ...utility };
      Object.keys(data).forEach((x) => {
        updateUtil[x] = data[x];
      });
      const updatedUtility = await this.api
        .sendRequest<Utility>('PUT', `/utility/${utility.utility_id}/`, null, updateUtil)
        .toPromise();

      this.utilityTableSource.data = this.utilityTableSource.data.map((x) => {
        if (x.utility_id === updatedUtility.utility_id) {
          return updatedUtility;
        } else {
          return x;
        }
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating utility');
    } finally {
      this.loadingUtilities = false;
      this.emitLoading();
    }
  }

  async putUtilityCalculation(data: Partial<UtilityCalculation>, utilityCalculation: UtilityCalculation) {
    try {
      this.loadingUtilities = true;
      this.emitLoading();
      const update = { ...utilityCalculation };
      Object.keys(data).forEach((x) => {
        update[x] = data[x];
      });
      const updatedUtility = await this.api
        .sendRequest<Utility>('PUT', `/utility/${utilityCalculation.utility_id_fk}/calculation`, null, update)
        .toPromise();

      this.utilityTableSource.data = this.utilityTableSource.data.map((x) => {
        if (x.utility_id === updatedUtility.utility_id) {
          return updatedUtility;
        } else {
          return x;
        }
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating utility calculations');
    } finally {
      this.loadingUtilities = false;
      this.emitLoading();
    }
  }

  async createUtilityCalculation(data: Partial<UtilityCalculation>, utilityCalculation: UtilityCalculation) {
    try {
      this.loadingUtilities = true;
      this.emitLoading();
      const update = { ...utilityCalculation };
      Object.keys(data).forEach((x) => {
        update[x] = data[x];
      });
      const updatedUtility = await this.api
        .sendRequest<Utility>('POST', `/utility/calculation`, null, update)
        .toPromise();

      this.utilityTableSource.data = this.utilityTableSource.data.map((x) => {
        if (x.utility_id === updatedUtility.utility_id) {
          return updatedUtility;
        } else {
          return x;
        }
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating utility calculations');
    } finally {
      this.loadingUtilities = false;
      this.emitLoading();
    }
  }
}
