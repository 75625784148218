import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-general-control',
  templateUrl: './admin-general-control.component.html',
  styleUrls: ['./admin-general-control.component.scss'],
})
export class AdminGeneralControlComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
