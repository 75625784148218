import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ServiceFilterOption, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { FileType, InvoiceFile } from 'src/app/interfaces/invoice-file';
import { InvoiceStatus, InvoiceStatusOption } from 'src/app/interfaces/invoice.model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DisplayErrorDialogComponent } from 'src/app/dialogs/display-error-dialog/display-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchResponseFile } from 'src/app/interfaces-search/search-response-file';
import { ProcessingError } from 'src/app/interfaces/processing-error';
import { SearchResponseCustomer } from 'src/app/interfaces-search/search-response-customer';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';

enum FilterSlug {
  customer = 'customer_name',
  customerIdFk = 'customer_id_fk',
  utility = 'utility_name',
  utilityType = 'utility_type',
  utilityIdFk = 'utility_id_fk',
  status = 'process_status',
  source = 'source_link',
  startTime = 'start_time',
  endTime = 'end_time',
  fileType = 'file_type',
}
@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  FilterSlug = FilterSlug;
  FileType = FileType;
  displayedColumns: string[] = [
    // 'checked',
    'source_link',
    'file_type',
    'customer_name',
    'utility_name',
    'utility_type',
    'start_time',
    'end_time',
    'process_status',
    'reprocess',
  ];
  services: { label: string; value: any }[] = Object.entries(ServiceFilterOption).map(([label, value]) => ({
    label,
    value,
  }));
  SelectType = SelectType;
  service: string = ServiceFilterOption.all;
  InvoiceStatus = InvoiceStatus;
  InvoiceStatusOption = InvoiceStatusOption;
  fileTableSource: MatTableDataSource<InvoiceFile>;
  utilityProviders: string[] = [];
  allSelected: boolean = false;
  loading: boolean = false;
  intervalNumber: number = 10000;
  interval: any = null;
  filterSelections: SelectOption[];

  constructor(
    private router: Router,
    private api: ApiService,
    private snackbar: SnackbarService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fileTableSource = new MatTableDataSource([]);
  }

  ngAfterViewInit(): void {
    this.fileTableSource.paginator = this.paginator;
    this.fileTableSource.sort = this.sort;
    this.getFiles();
    this.pollFiles();
    this.fileTableSource.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.fileTableSource.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getFiles(skip, take);
      }
    });
    this.ref.detectChanges();
    this.filterSelections = [
      {
        name: 'Customer',
        slug: FilterSlug.customerIdFk,
        options: [],
        value: '',
        labelInputValue: '',
        allowPartial: false,
        debounceInputs: true,
        type: SelectType.typeAheadAsync,
        updateFunction: async (filterSelection: SelectOption) => {
          const obj = { take: 10 };
          if (filterSelection.labelInputValue) {
            obj['customer_name'] = filterSelection.labelInputValue;
          }
          const { items = [] } = await this.api
            .sendRequest<BaseResponse<SearchResponseCustomer[]>>('GET', '/customer/', obj)
            .toPromise();
          filterSelection.options = items.map((x) => {
            return {
              label: x.Customer.customer_name,
              value: x.Customer.customer_id,
            };
          });
        },
      },
      {
        name: 'File Type',
        slug: FilterSlug.fileType,
        value: '',
        options: Object.entries(FileType).map(([value, label]) => ({
          label,
          value,
        })),
        type: SelectType.select,
        allowPartial: false,
      },
      {
        name: 'Utility Provider',
        slug: FilterSlug.utilityIdFk,
        options: [],
        value: '',
        labelInputValue: '',
        allowPartial: false,
        debounceInputs: true,
        type: SelectType.typeAheadAsync,
        updateFunction: async (filterSelection: SelectOption) => {
          const obj = { take: 10 };
          if (filterSelection.labelInputValue) {
            obj['utility_name'] = filterSelection.labelInputValue;
          }
          const { items = [] } = await this.api
            .sendRequest<BaseResponse<Utility[]>>('GET', '/utility/', obj)
            .toPromise();
          filterSelection.options = items.map((x) => {
            return {
              label: x.utility_name,
              value: x.utility_id,
            };
          });
        },
      },
      {
        name: 'Status',
        slug: FilterSlug.status,
        value: '',
        options: Object.entries(InvoiceStatusOption).map(([value, label]) => ({
          label,
          value,
        })),
        type: SelectType.typeAhead,
        allowPartial: false,
      },
    ];
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async getFiles(skip: number = 0, take: number = 100) {
    try {
      this.loading = true;
      const obj = { skip, take };
      this.filterSelections &&
        this.filterSelections.forEach((filter) => {
          if (filter.value) {
            obj[filter.slug] = filter.value;
          }
        });
      let { items = [], total = null } = await this.api
        .sendRequest<BaseResponse<SearchResponseFile[]>>('GET', `/file/detailed`, obj)
        .toPromise();
      const invoiceFiles: InvoiceFile[] = items.map((x) => {
        return {
          file_id: x.file_id,
          customer_id_fk: x.customer_id_fk,
          utility_id_fk: x.utility_id_fk,
          source_link: x.source_link,
          process_status: x.process_status,
          start_time: x.start_time,
          end_time: x.end_time,
          file_type: x.file_type,
          created_by: x.created_by,
          created_on: x.created_on,
          archived_on: x.archived_on,
          last_updated_on: x.last_updated_on,
          last_updated_by: x.last_updated_by,
          customer_name: x.customer.customer_name,
          utility_type: x.utility.utility_type,
          utility_name: x.utility.utility_name,
          ready_for_processing: x.utility.utility_ready_for_processing,
        };
      });
      if (skip !== 0 && (!items || items.length === 0)) {
        return;
      }
      if (total !== 0 && total !== null) {
        this.fileTableSource.paginator._intl = new CustomMatPaginatorIntl(total);
      }

      const data = this.fileTableSource.data;
      this.fileTableSource.data = skip > 0 ? data.concat(invoiceFiles) : invoiceFiles;
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving invoices');
    } finally {
      this.loading = false;
      this.ref.detectChanges();
    }
  }

  getFilterSelection(slug: FilterSlug): SelectOption {
    return this.filterSelections.find((x) => x.slug === slug);
  }

  getAsciiMapValue(s: string) {
    //to make sure the values are valid map values
    return 'key' + [...s].map((x) => x.charCodeAt(0)).reduce((a, b) => a + b);
  }

  async getFile(file: InvoiceFile) {
    try {
      this.loading = true;
      let fileLink = await this.api.sendRequest<string>('GET', `/file/${file.file_id}`).toPromise();
      window.open(fileLink, '_blank');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error generating download link');
    } finally {
      this.loading = false;
    }
  }

  async reprocess(file: InvoiceFile) {
    try {
      this.loading = true;
      await this.api.sendRequest('POST', `/file/${file.file_id}/reprocess`).toPromise();
      file.process_status = InvoiceStatus.PENDING;
      this.snackbar.showInfo('File reprocessing');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error reprocessing invoice');
    } finally {
      this.loading = false;
    }
  }

  goToFile() {
    // do something
  }

  pollFiles() {
    this.interval = setInterval(() => {
      this.getStatus();
    }, this.intervalNumber);
  }

  async getStatus() {
    try {
      this.loading = true;
      const files = this.fileTableSource.data.filter((x) =>
        [
          InvoiceStatus.RUNNING,
          InvoiceStatus.PENDING,
          InvoiceStatus.OCR_COMPLETE,
          InvoiceStatus.DB_INSERT_RUNNING,
        ].includes(x.process_status)
      );
      if (files.length === 0) {
        this.loading = false;
        return;
      }

      let file_ids = files.map((x) => x.file_id).join(',');
      const items = await this.api
        .sendRequest<{ file_id: number; process_status: InvoiceStatus; start_time: string; end_time: string }[]>(
          'GET',
          `/file/status/batch?file_ids=${file_ids}`
        )
        .toPromise();
      const data = this.fileTableSource.data;
      items.forEach((item) => {
        const row = data.find((x) => x.file_id === item.file_id);
        if (row) {
          row.process_status = item.process_status;
          row.start_time = item.start_time;
          row.end_time = item.end_time;
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  filterData() {
    this.getFiles();
  }

  uploadFiles() {
    this.router.navigate(['upload-files']);
  }

  statusClicked(file: InvoiceFile) {
    if (file.process_status === InvoiceStatus.ERROR || file.process_status === InvoiceStatus.COMPLETE_WITH_ERRORS) {
      this.showErrorReport(file);
    }
  }

  async showErrorReport(file: InvoiceFile) {
    try {
      this.loading = true;
      // const errors = await
      const source_link = file.source_link;
      const { items = [] } = await this.api
        .sendRequest<BaseResponse<ProcessingError[]>>('GET', '/invoice_processing_error/source/', { source_link })
        .toPromise();
      const errors = items.map((item) => {
        return {
          error_type: item?.error_type,
          error_message: item?.error_message,
        };
      });
      this.showErrorReportDialog(errors, 'File Error');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error getting error report');
    } finally {
      this.loading = false;
    }
  }

  showErrorReportDialog(errors: { error_type: string; error_message: string }[], title: string) {
    const dialogRef = this.dialog.open(DisplayErrorDialogComponent, {
      width: '800px',
      panelClass: 'no-padding-dialog',
      data: {
        errors,
        title,
      },
    });
  }

  disableReprocessing(status: InvoiceStatus) {
    return [
      InvoiceStatus.RUNNING,
      InvoiceStatus.PENDING,
      InvoiceStatus.OCR_COMPLETE,
      InvoiceStatus.DB_INSERT_RUNNING,
    ].includes(status);
  }
}
