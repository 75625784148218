import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-edit-invoice-item',
  templateUrl: './edit-invoice-item.component.html',
  styleUrls: ['./edit-invoice-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EditInvoiceItemComponent,
    },
  ],
})
export class EditInvoiceItemComponent implements OnInit, ControlValueAccessor {
  @Input()
  value: string | number;

  @Input()
  name: string;

  @Input()
  form: FormGroup;

  @Input()
  filter: string;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  edit: boolean;
  touched = false;
  disabled = false;
  originalValue: string | number;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {
    this.edit = false;
    this.originalValue = this.value;
  }

  getRowHeight(): number {
    if (typeof this.value !== 'string') {
      return 1;
    }
    const length = this.value?.split('\n').length;
    if (length > 0) return length;
    if (length > 5) return 5;
    return 1;
  }

  toggleEdit(e): void {
    this.edit = !this.edit;
  }

  cancelEdit(): void {
    this.edit = false;
    this.value = this.originalValue;
  }

  submitEdit(): void {
    this.markAsTouched();
    if (!this.disabled) {
      if (typeof this.value === 'string') {
        this.value = this.value.trim();
      }
      this.onChange(this.value);
      this.originalValue = this.value;
      this.onSubmit.emit();
      this.edit = false;
    }
  }

  writeValue(value: string): void {
    this.value = value;
    this.originalValue = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  getIsEmpty() {
    // Returns true if the value is empty
    console.log(this.name + ': ' + this.value + ' | ' + this.originalValue);
    if (this.value === 0) return false;
    return this.value === '' || this.value === null;
  }
}
