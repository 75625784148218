import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { AddUtilityProviderDialogComponent } from 'src/app/dialogs/add-utility-provider-dialog/add-utility-provider-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { Customer } from 'src/app/interfaces/customer';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { Service, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

interface CustomerUtilitiesResponse extends Customer {
  customer_utilities: Utility[];
}

@Component({
  selector: 'app-utility-providers-table',
  templateUrl: './utility-providers-table.component.html',
  styleUrls: ['./utility-providers-table.component.scss'],
})
export class UtilityProvidersTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() customer: Customer;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  customerName: string;
  displayedColumns: string[] = ['utility_name', 'utility_type', 'utility_metadata.is_single_invoice', 'actions'];
  includeArchived: boolean = false;
  providers: MatTableDataSource<Utility>;
  filter: SelectOption = {
    name: 'Filter by name',
    slug: 'utility_name',
    options: [],
    value: '',
    allowPartial: true,
    type: SelectType.typeAhead,
  };

  get isAdmin() {
    return this.authService.isAdmin;
  }

  constructor(
    private dialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.providers = new MatTableDataSource([]);
    this.getUtilityProvders();
  }

  ngAfterViewInit(): void {
    this.providers.paginator = this.paginator;
    this.providers.sort = this.sort;
    this.providers.filterPredicate = (data, filter: string): boolean => {
      return data.utility_name.toLowerCase().includes(filter);
    };
    this.providers.sortingDataAccessor = (item, property) => {
      const slugs = property.split('.');
      if (slugs.length === 1) {
        return item[slugs[0]];
      }
      let currentItem = { ...item };
      slugs.forEach((slug) => {
        if (currentItem[slug]) {
          currentItem = currentItem[slug];
        }
      });
      return currentItem;
    };
    this.providers.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (
        x.pageIndex === this.paginator.getNumberOfPages() - 1 &&
        this.providers.data.length >= this.paginator.pageSize
      ) {
        const skip = this.providers.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getUtilityProvders(skip, take);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  filterData() {
    const filterValue = this.filter.value;
    this.providers.filter = filterValue.trim().toLowerCase();
  }

  changeArchived(includeArchived: boolean): void {
    this.includeArchived = includeArchived;
    this.filterData();
  }

  async getUtilityProvders(skip: number = 0, take: number = 100) {
    try {
      this.loading.next(true);
      const { customer_utilities, customer_name } = await this.api
        .sendRequest<CustomerUtilitiesResponse>('GET', `/customer/${this.customer.customer_id}/utilities/`, {
          skip,
          take,
        })
        .toPromise();
      this.customerName = customer_name;
      if (skip !== 0 && (!customer_utilities || customer_utilities.length === 0)) {
        return;
      }
      console.log(skip);
      const data = this.providers.data;
      this.providers.data = skip > 0 ? data.concat(customer_utilities) : customer_utilities;
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving utlility providers');
    } finally {
      this.loading.next(false);
    }
  }

  async updateUtility(utility: Utility) {
    let utilityOptions;
    try {
      this.loading.next(true);
      const { items } = await this.api.sendRequest<BaseResponse<Utility[]>>('GET', `/utility/`).toPromise();
      utilityOptions = items.map((x) => {
        return {
          value: x.utility_id,
          display: x.utility_name,
        };
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error getting utilities');
      return;
    } finally {
      this.loading.next(false);
    }
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Edit Utility Provider',
        customer: this.customerName,
        addressType: 'Billing',
        formItems: [
          {
            slug: 'utility_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: utility.utility_name,
          },
          {
            slug: 'utility_id_fk',
            name: 'Service',
            required: true,
            type: AddFormItem.select,
            selectItems: utilityOptions,
            currentValue: utility.utility_id,
          },
          {
            slug: 'utility_is_third_party',
            name: 'Distribution Company',
            required: false,
            type: AddFormItem.checkbox,
            currentValue: utility.utility_is_third_party,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        // this.updateUtilityProvider(result.data);
      }
    });
  }

  async addUtilityProvider() {
    let utilityOptions;
    try {
      this.loading.next(true);
      const { items } = await this.api.sendRequest<BaseResponse<Utility[]>>('GET', `/utility/`).toPromise();
      utilityOptions = items.map((x) => {
        return {
          value: x.utility_id,
          display: x.utility_name,
        };
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error getting utilities');
      return;
    } finally {
      this.loading.next(false);
    }
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Add Utility Provider to customer',
        customer: this.customerName,
        formItems: [
          {
            slug: 'utility_id_fk',
            name: 'Provider',
            required: true,
            type: AddFormItem.select,
            selectItems: utilityOptions,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.createUtilityProvider(result.data);
      }
    });
  }

  async createUtilityProvider(data: { utility_id_fk: string }) {
    try {
      this.loading.next(true);
      await this.api
        .sendRequest('POST', `/customer/${this.customer.customer_id}/utility?utility_id=${data.utility_id_fk}`)
        .toPromise();
      this.getUtilityProvders();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error creating utlility');
    } finally {
      this.loading.next(false);
    }
  }

  deleteUtilityProviderDialog(utility: Utility) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Utility From Customer',
        subtitle: utility.utility_name,
        message: `Are you sure you want to delete ${utility.utility_name} from ${this.customerName}?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteUtilityProvider(utility);
      }
    });
  }

  async deleteUtilityProvider(utility: Utility) {
    try {
      this.loading.next(true);
      await this.api
        .sendRequest('DELETE', `/customer/${this.customer.customer_id}/utility?utility_id=${utility.utility_id}`)
        .toPromise();
      this.providers.data = this.providers.data.filter((x) => x.utility_id !== utility.utility_id);
    } catch (err) {
      this.snackbar.showError('Error deleting utility provider');
      console.log(err);
    } finally {
      this.loading.next(false);
    }
  }

  async updateUtilityProvider(
    data: {
      utility_name: string;
      utility_type: string;
      utility_is_third_party: boolean;
    },
    utilityId: string
  ) {
    try {
      this.loading.next(true);
      await this.api.sendRequest('PUT', `/utility/${utilityId}`, null, data).toPromise();
      this.getUtilityProvders();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error creating utlility');
    } finally {
      this.loading.next(false);
    }
  }

  goToUtilityProvider(utilityId: string) {
    this.router.navigate(['invoice-fields', utilityId]);
  }
}
