import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Address } from 'src/app/interfaces/address';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { Customer } from 'src/app/interfaces/customer';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { ServiceAccount, ServiceAccountDisplay } from 'src/app/interfaces/service-account';
import { Utility } from 'src/app/interfaces/utility';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-service-accounts-table',
  templateUrl: './service-accounts-table.component.html',
  styleUrls: ['./service-accounts-table.component.scss'],
})
export class ServiceAccountsTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() customer: Customer;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  includeArchived: boolean = false;
  displayedColumns: string[] = [
    'account_name',
    'account_number',
    'account_alias',
    'account_start_date',
    'account_end_date',
    'address.address_alias',
    'address.address_city',
    'address.address_state',
    'active',
    'actions',
  ];
  serviceAccounts: MatTableDataSource<ServiceAccount>;
  filters: SelectOption[] = [
    {
      name: 'Filter by name',
      slug: 'account_name',
      options: [],
      value: '',
      allowPartial: true,
      type: SelectType.typeAhead,
      debounceInputs: true,
    },
  ];

  get isAdmin() {
    return this.authService.isAdmin;
  }

  constructor(
    private dialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.serviceAccounts = new MatTableDataSource([]);
    this.getServiceAccounts();
  }

  ngAfterViewInit(): void {
    this.serviceAccounts.paginator = this.paginator;
    this.serviceAccounts.sort = this.sort;
    this.serviceAccounts.sortingDataAccessor = (item, property) => {
      property = property === 'active' ? 'archived_on' : property; // active and archived_on look at the same thing
      const slugs = property.split('.');
      if (slugs.length === 1) {
        return item[slugs[0]];
      }
      let currentItem = { ...item };
      slugs.forEach((slug) => {
        if (currentItem[slug]) {
          currentItem = currentItem[slug];
        }
      });
      return currentItem;
    };
    this.serviceAccounts.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.serviceAccounts.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getServiceAccounts(skip, take);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  filterData() {
    const filterData: { slug: string; value: string }[] = this.filters
      .filter((x) => x.value)
      .map((x) => {
        return {
          slug: x.slug,
          value: x.value,
        };
      });
    this.getServiceAccounts(0, 100, filterData);
  }

  changeArchived(includeArchived: boolean): void {
    this.includeArchived = includeArchived;
    this.filterData();
  }

  async getServiceAccounts(skip: number = 0, take: number = 100, filters: { slug: string; value: string }[] = []) {
    try {
      this.loading.next(true);
      const queryItems: { [key: string]: number | string | boolean } = { skip, take };
      if (filters) {
        filters.forEach((filter) => {
          queryItems[filter.slug] = filter.value;
        });
      }
      queryItems.customer_id_fk = this.customer.customer_id;
      queryItems.include_archived = this.includeArchived;
      const { items, total } = await this.api
        .sendRequest<BaseResponse<ServiceAccountDisplay[]>>('GET', `/service_account/detailed`, queryItems)
        .toPromise();
      const serviceAccounts = [...items];
      const displayServiceAccounts: ServiceAccountDisplay[] = serviceAccounts.map((serviceAccount) => {
        return {
          ...serviceAccount,
        };
      });
      if (skip !== 0 && (!items || items.length === 0)) {
        return;
      }
      if (total && total !== 0 && total !== null) {
        this.serviceAccounts.paginator._intl = new CustomMatPaginatorIntl(total);
      }
      const data = this.serviceAccounts.data;
      this.serviceAccounts.data = skip > 0 ? data.concat(displayServiceAccounts) : displayServiceAccounts;
    } catch (err) {
      this.snackbar.showError('Error retrieving service accounts');
      console.log(err);
    } finally {
      this.loading.next(false);
    }
  }

  async addNewServiceAccountDialog() {
    let utilityOptions;
    try {
      this.loading.next(true);
      const { items } = await this.api.sendRequest<BaseResponse<Utility[]>>('GET', `/utility/`).toPromise();
      utilityOptions = items.map((x) => {
        return {
          value: x.utility_id,
          display: x.utility_name,
        };
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error getting utilities');
      return;
    } finally {
      this.loading.next(false);
    }
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Add New Service Account',
        addressType: 'Billing',
        formItems: [
          {
            slug: 'account_name',
            name: 'Account Name',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: 'account_alias',
            name: 'Account Alias',
            type: AddFormItem.text,
          },
          {
            slug: 'account_number',
            name: 'Customer Acct. No.',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: 'account_unique_identifier',
            name: 'Unique Identifier',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: 'utility_id_fk',
            name: 'Service',
            required: true,
            type: AddFormItem.select,
            selectItems: utilityOptions,
          },
          {
            slug: 'account_start_date',
            name: 'Service start',
            type: AddFormItem.date,
          },
          {
            slug: 'account_end_date',
            name: 'Service end',
            type: AddFormItem.date,
          },
          {
            slug: 'utility_address',
            name: 'Address',
            required: true,
            type: AddFormItem.address,
            currentValue: {
              item: null,
              street_address: 'address_alias',
              state: 'address_state',
              city: 'address_city',
              zip: 'address_zip',
              raw: 'address_raw',
            },
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.addNewServiceAccount(result.data);
      }
    });
  }

  async addNewServiceAccount(data: {
    address_alias: string;
    address_state: string;
    address_city: string;
    address_zip: string;
    utility_id_fk: string;
    account_unique_identifier: string;
    account_number: string;
    account_name: string;
    account_alias: string;
    account_start_date;
    account_end_date;
  }) {
    try {
      this.loading.next(true);
      const {
        account_number,
        account_name,
        account_alias,
        utility_id_fk,
        account_start_date,
        account_end_date,
        account_unique_identifier,
        ...addressData
      } = data;
      const address = await this.api.sendRequest<Address>('POST', `/address/`, null, addressData).toPromise();

      const serviceAccountData = {
        customer_id_fk: this.customer.customer_id,
        address_id_fk: address.address_id,
        account_end_date,
        account_unique_identifier,
        account_start_date,
        utility_id_fk,
        account_name,
        account_alias,
        account_number,
      };
      await this.api
        .sendRequest<ServiceAccount>('POST', '/service_account/', null, {
          ...serviceAccountData,
        })
        .toPromise();
      this.getServiceAccounts();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error adding new service account');
    } finally {
      this.loading.next(false);
    }
  }

  async updateServiceAccountDialog(serviceAccount: ServiceAccountDisplay) {
    let utilityOptions;
    try {
      this.loading.next(true);
      const { items } = await this.api.sendRequest<BaseResponse<Utility[]>>('GET', `/utility/`).toPromise();
      utilityOptions = items.map((x) => {
        return {
          value: x.utility_id,
          display: x.utility_name,
        };
      });
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error getting utilities');
      return;
    } finally {
      this.loading.next(false);
    }
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Update Service Account',
        addressType: 'Billing',
        formItems: [
          {
            slug: 'account_name',
            name: 'Account Name',
            required: true,
            type: AddFormItem.text,
            currentValue: serviceAccount.account_name,
          },
          {
            slug: 'account_alias',
            name: 'Account Alias',
            type: AddFormItem.text,
            currentValue: serviceAccount.account_alias,
          },
          {
            slug: 'account_number',
            name: 'Customer Acct. No.',
            required: true,
            type: AddFormItem.text,
            currentValue: serviceAccount.account_number,
          },
          {
            slug: 'account_unique_identifier',
            name: 'Unique Identifier',
            required: true,
            type: AddFormItem.text,
            currentValue: serviceAccount.account_unique_identifier,
          },
          {
            slug: 'utility_id_fk',
            name: 'Utility Id',
            required: true,
            type: AddFormItem.select,
            selectItems: utilityOptions,
            currentValue: serviceAccount.utility_id_fk,
          },
          {
            slug: 'account_start_date',
            name: 'Service start',
            type: AddFormItem.date,
            currentValue: serviceAccount.account_start_date,
          },
          {
            slug: 'account_end_date',
            name: 'Service end',
            type: AddFormItem.date,
            currentValue: serviceAccount.account_end_date,
          },
          {
            slug: 'utility_address',
            name: 'Address',
            required: true,
            type: AddFormItem.address,
            currentValue: {
              item: serviceAccount.address,
              street_address: 'address_alias',
              state: 'address_state',
              city: 'address_city',
              zip: 'address_zip',
              raw: 'address_raw',
            },
          },
          {
            slug: 'address_latitude',
            name: 'Latitude',
            required: false,
            type: AddFormItem.number,
            currentValue: serviceAccount.address.address_latitude,
          },
          {
            slug: 'address_longitude',
            name: 'Longitude',
            required: false,
            type: AddFormItem.number,
            currentValue: serviceAccount.address.address_longitude,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.updateServiceAccount(result.data, serviceAccount);
      }
    });
  }

  async updateServiceAccount(
    data: {
      account_alias: string;
      address_alias: string;
      address_state: string;
      address_city: string;
      address_zip: string;
      address_latitude: number;
      address_longitude: number;
      utility_id_fk: string;
      account_number: string;
      account_name: string;
      account_start_date: string;
      account_end_date: string;
      account_unique_identifier: string;
    },
    serviceAccountToUpdate: ServiceAccountDisplay
  ) {
    try {
      this.loading.next(true);
      const {
        account_number,
        account_name,
        account_alias,
        utility_id_fk,
        account_start_date,
        account_end_date,
        account_unique_identifier,
        ...addressData
      } = data;

      await this.api
        .sendRequest<Address>('PUT', `/address/${serviceAccountToUpdate.address_id_fk}/`, null, addressData)
        .toPromise();
      const serviceAccountData = {
        account_alias,
        address_id_fk: serviceAccountToUpdate.address_id_fk,
        account_end_date,
        account_start_date,
        utility_id_fk,
        account_name,
        account_number,
        account_unique_identifier,
      };
      const serviceAccount = await this.api
        .sendRequest<ServiceAccount>(
          'PUT',
          `/service_account/${serviceAccountToUpdate.account_id}/`,
          null,
          serviceAccountData
        )
        .toPromise();
      this.getServiceAccounts();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating service account');
    } finally {
      this.loading.next(false);
    }
  }

  deleteServiceAccountDialog(serviceAccount: ServiceAccountDisplay) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Serice Account',
        subtitle: serviceAccount.account_name,
        message: `Are you sure you want to delete ${serviceAccount.account_name}?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteServiceAccount(serviceAccount);
      }
    });
  }

  async deleteServiceAccount(serviceAccountToUpdate: ServiceAccountDisplay) {
    try {
      this.loading.next(true);
      await this.api.sendRequest('DELETE', `/service_account/${serviceAccountToUpdate.account_id}/`, null).toPromise();
      this.getServiceAccounts();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error adding new service account');
    } finally {
      this.loading.next(false);
    }
  }
}
