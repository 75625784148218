import { Component, Input, OnInit } from '@angular/core';
import { InvoiceDataItem } from '../view-pdf.component';

@Component({
  selector: 'app-invoice-item',
  templateUrl: './invoice-item.component.html',
  styleUrls: ['./invoice-item.component.scss'],
})
export class InvoiceItemComponent implements OnInit {
  @Input() item: InvoiceDataItem;
  boxClass: string;
  confidenceLabel: string;

  constructor() {}

  get confidence() {
    return `${Math.round(this.item.confidence * 100)}%`;
  }

  ngOnInit(): void {
    this.boxClass = this.getSquareClass();
    this.confidenceLabel = this.getConifidenceLabel();
  }

  getSquareClass(): string {
    if (this.item.confidence >= 0.9) {
      return 'green-box';
    } else if (this.item.confidence >= 0.7) {
      return 'yellow-box';
    } else {
      return 'red-box';
    }
  }

  getConifidenceLabel(): string {
    if (this.item.confidence >= 0.9) {
      return 'High Confidence';
    } else if (this.item.confidence >= 0.7) {
      return 'Medium Confidence';
    } else {
      return 'Low Confidence ';
    }
  }
}
