import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, MAT_PAGINATOR_INTL_PROVIDER } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DatePipe } from '@angular/common';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

// Services
import { AuthService } from './services/auth.service';
import { SnackbarService } from './services/snackbar.service';
import { AuthGuard } from './services/auth-guard.service';
import { ApiService } from './services/api.service';
import { ViewPdfComponent } from './pages/view-pdf/view-pdf.component';
import { CustomerDetailComponent } from './pages/customer-detail/customer-detail.component';
import { ServiceAccountsTableComponent } from './pages/customer-detail/tables/service-accounts-table/service-accounts-table.component';
import { UtilityProvidersTableComponent } from './pages/customer-detail/tables/utility-providers-table/utility-providers-table.component';
import { AddUtilityProviderDialogComponent } from './dialogs/add-utility-provider-dialog/add-utility-provider-dialog.component';
import { UploadInvoicesComponent } from './pages/upload-invoices/upload-invoices.component';
import { UtilityProvidersComponent } from './pages/utility-providers/utility-providers.component';
import { ProviderTableComponent } from './pages/utility-providers/provider-table/provider-table.component';
import { EditInvoiceFieldsComponent } from './pages/edit-invoice-fields/edit-invoice-fields.component';
import { UtlityProviderDetailComponent } from './pages/utlity-provider-detail/utlity-provider-detail.component';
import { AddItemDialogComponent } from './dialogs/add-item-dialog/add-item-dialog.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { InvoiceFieldFormComponent } from './pages/upload-invoices/invoice-field-form/invoice-field-form.component';
import { FileNamePipe } from './pipes/file-name.pipe';
import { FilesComponent } from './pages/files/files.component';
import { InvoiceItemComponent } from './pages/view-pdf/invoice-item/invoice-item.component';
import { DisplayErrorDialogComponent } from './dialogs/display-error-dialog/display-error-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { InterceptorService } from './services/interceptor.service';
import { AdminUserControlComponent } from './pages/admin-user-control/admin-user-control.component';
import { AdminGeneralControlComponent } from './pages/admin-general-control/admin-general-control.component';
import { EnergyRegionsComponent } from './pages/admin-general-control/tabs/energy-regions/energy-regions.component';
import { EnergyRegionZipmapComponent } from './pages/admin-general-control/tabs/energy-region-zipmap/energy-region-zipmap.component';
import { EditInvoiceFormComponent } from './pages/view-pdf/edit-invoice-form/edit-invoice-form.component';
import { EditInvoiceItemComponent } from './pages/view-pdf/edit-invoice-item/edit-invoice-item.component';
import { EditInvoiceItemStateComponent } from './pages/view-pdf/edit-invoice-item-state/edit-invoice-item-state.component';
import { ViewPdfSidebarContentComponent } from './pages/view-pdf/view-pdf-sidebar-content/view-pdf-sidebar-content.component';
import { ErrorComponent } from './pages/error/error.component';
import { InvoiceNotesTabComponent } from './pages/view-pdf/invoice-notes-tab/invoice-notes-tab.component';
import { InvoiceNotesTabItemComponent } from './pages/view-pdf/invoice-notes-tab/invoice-notes-tab-item/invoice-notes-tab-item.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    ViewPdfComponent,
    CustomerDetailComponent,
    ServiceAccountsTableComponent,
    UtilityProvidersTableComponent,
    AddUtilityProviderDialogComponent,
    UploadInvoicesComponent,
    UtilityProvidersComponent,
    ProviderTableComponent,
    EditInvoiceFieldsComponent,
    UtlityProviderDetailComponent,
    AddItemDialogComponent,
    InvoicesComponent,
    ProfileComponent,
    CustomerComponent,
    ConfirmationDialogComponent,
    InvoiceFieldFormComponent,
    FileNamePipe,
    FilesComponent,
    InvoiceItemComponent,
    DisplayErrorDialogComponent,
    AdminUserControlComponent,
    AdminGeneralControlComponent,
    EnergyRegionsComponent,
    EnergyRegionZipmapComponent,
    EditInvoiceFormComponent,
    EditInvoiceItemComponent,
    EditInvoiceItemStateComponent,
    ViewPdfSidebarContentComponent,
    ErrorComponent,
    InvoiceNotesTabComponent,
    InvoiceNotesTabItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatBadgeModule,
    MatSortModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatTableModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    SharedModule,
  ],
  providers: [
    AuthService,
    DatePipe,
    MatDatepickerModule,
    SnackbarService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    /* EventBusService,*/
    ApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
