import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { SelectOption } from 'src/app/interfaces/select-option';

@Component({
  selector: 'app-type-ahead-async',
  templateUrl: './type-ahead-async.component.html',
  styleUrls: ['./type-ahead-async.component.scss'],
})
export class TypeAheadAsyncComponent implements OnInit {
  @Input() selectOption: SelectOption;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  formGroup: FormGroup;
  currentValue: string = '';

  get filteredOptions(): { value: string; label: string }[] {
    if (this.selectOption?.options?.length === 0) {
      return [];
    }
    return this.selectOption?.options;
  }

  constructor() {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      typeAheadControl: new FormControl(),
    });

    this.selectOption.updateFunction(this.selectOption);
    const control = this.formGroup.get('typeAheadControl');
    const debounceInputs = this.selectOption?.debounceInputs ? true : false;

    control.valueChanges.pipe(debounce(() => interval(debounceInputs ? 600 : 0))).subscribe((value) => {
      this.selectOption.labelInputValue = value;
      this.selectOption.updateFunction(this.selectOption);
    });
  }

  onClear(): void {
    this.selectOption.value = '';
    this.valueChange.next(null);
  }

  // TODO: Sets value change
  onSelect(value: string): void {
    this.selectOption.value = value;
    this.valueChange.next(null);
  }
}
