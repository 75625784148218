import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReplaySubject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { MatSort } from '@angular/material/sort';
import { EPASubregion } from 'src/app/interfaces/epa-subregions';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';

@Component({
  selector: 'app-energy-regions',
  templateUrl: './energy-regions.component.html',
  styleUrls: ['./energy-regions.component.scss'],
})
export class EnergyRegionsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  energyRegions: MatTableDataSource<EPASubregion>;
  loading: boolean = false;

  displayedColumns: string[] = [
    'name',
    'acronym',
    'co2rta',
    'ch4rta',
    'n2orta',
    'c2erta',
    'noxrta',
    'so2rta',
    'ggrsloss',
    'actions',
  ];

  filters: SelectOption[] = [
    {
      name: 'Search by name',
      slug: 'name',
      options: [],
      value: '',
      allowPartial: true,
      debounceInputs: true,
      type: SelectType.typeAhead,
    },
    {
      name: 'Search by acronym',
      slug: 'acronym',
      options: [],
      value: '',
      allowPartial: true,
      debounceInputs: true,
      type: SelectType.typeAhead,
    },
  ];

  constructor(private snackbar: SnackbarService, private api: ApiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.energyRegions = new MatTableDataSource([]);
    this.getRegions();
  }

  ngAfterViewInit(): void {
    this.energyRegions.paginator = this.paginator;
    this.energyRegions.sort = this.sort;

    this.energyRegions.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.energyRegions.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getRegions(skip, take);
      }
    });
  }

  async getRegions(skip: number = 0, take: number = 100): Promise<void> {
    try {
      this.loading = true;

      const queryItems = { skip, take };
      this.filters.forEach((filter) => {
        if (filter.value !== '') {
          queryItems[filter.slug] = filter.value;
        }
      });

      let { items, total = 0 } = await this.api
        .sendRequest<BaseResponse<EPASubregion[]>>('GET', '/epa_subregion/', queryItems)
        .toPromise();

      if (total !== 0 && total !== null) {
        this.energyRegions.paginator._intl = new CustomMatPaginatorIntl(total);
      }
      this.energyRegions.data = skip > 0 ? this.energyRegions.data.concat(items) : items;
    } catch (ex) {
      this.snackbar.showError(`Error while loading regions, ${ex}`);
    } finally {
      this.loading = false;
    }
  }

  async addRegion(): Promise<void> {
    try {
      const dialogRef = this.dialog.open(AddItemDialogComponent, {
        width: '350px',
        data: {
          title: 'Add EPA Subregion',
          formItems: [
            {
              slug: 'name',
              name: 'Name',
              required: true,
              type: AddFormItem.text,
              currentValue: '',
            },
            {
              slug: 'acronym',
              name: 'Acronym',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'co2rta',
              name: 'Annual CO2 output emission rate',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'ch4rta',
              name: 'Annual CH4 output emission rate',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'n2orta',
              name: 'Annual N2O output emission rate',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'c2erta',
              name: 'Annual CO2e output emission rate',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'noxrta',
              name: 'Annual NOX output emission rate',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'so2rta',
              name: 'Annual SO2 output emission rate',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'ggrsloss',
              name: 'Grid gross loss',
              required: true,
              type: AddFormItem.text,
            },
          ],
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.action === 'success') {
          this.postRegion(result.data);
        }
      });
    } catch {
      this.snackbar.showError('Unknown error');
    }
  }

  async postRegion(data: {
    name: string;
    acronym: string;
    co2rta: number;
    ch4rta: number;
    n2orta: number;
    c2erta: number;
    noxrta: number;
    so2rta: number;
    ggrsloss: number;
  }) {
    try {
      this.loading = true;

      for (let key in data) data[key] = data[key].length > 0 ? data[key] : null;

      await this.api.sendRequest<EPASubregion>('POST', `/epa_subregion/`, null, data).toPromise();
      this.getRegions();
    } catch (ex) {
      this.snackbar.showError(ex.error.detail ?? 'Error creating region');
    } finally {
      this.loading = false;
    }
  }

  async updateRegion(currentRegion: EPASubregion) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '350px',
      data: {
        title: 'Update Region',
        formItems: [
          {
            slug: 'name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.name,
          },
          {
            slug: 'acronym',
            name: 'Acronym',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.acronym,
          },
          {
            slug: 'co2rta',
            name: 'Annual CO2 output emission rate',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.co2rta,
          },
          {
            slug: 'ch4rta',
            name: 'Annual CH4 output emission rate',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.ch4rta,
          },
          {
            slug: 'n2orta',
            name: 'Annual N2O output emission rate',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.n2orta,
          },
          {
            slug: 'c2erta',
            name: 'Annual CO2e output emission rate',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.c2erta,
          },
          {
            slug: 'noxrta',
            name: 'Annual NOX output emission rate',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.noxrta,
          },
          {
            slug: 'so2rta',
            name: 'Annual SO2 output emission rate',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.so2rta,
          },
          {
            slug: 'ggrsloss',
            name: 'Grid gross loss',
            required: true,
            type: AddFormItem.text,
            currentValue: currentRegion.ggrsloss,
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.putRegion(currentRegion, result.data);
      }
    });
  }

  async putRegion(
    regionOriginal: EPASubregion,
    newData: {
      name: string;
      acronym: string;
      co2rta: number;
      ch4rta: number;
      n2orta: number;
      c2erta: number;
      noxrta: number;
      so2rta: number;
      ggrsloss: number;
    }
  ) {
    Object.keys(newData).forEach((element) => {
      regionOriginal[element] = newData[element].length > 0 ? newData[element] : null;
    });

    try {
      this.loading = true;
      await this.api
        .sendRequest<EPASubregion>('PUT', `/epa_subregion/${regionOriginal.id}/`, null, newData)
        .toPromise();
      this.getRegions();
    } catch (ex) {
      this.snackbar.showError(ex.error.detail ?? 'Error updating region');
    } finally {
      this.loading = false;
    }
  }

  async removeRegion(currentRegion: EPASubregion) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Region',
        subtitle: `${currentRegion.name} (${currentRegion.acronym})`,
        message: `Are you sure you want to delete ${currentRegion.name} (${currentRegion.acronym})?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteRegion(currentRegion);
      }
    });
  }

  async deleteRegion(targetRegion: EPASubregion) {
    try {
      this.loading = true;
      await this.api.sendRequest('DELETE', `/epa_subregion/${targetRegion.id}/`).toPromise();
      this.getRegions();
    } catch (ex) {
      this.snackbar.showError('Unknown error deleting region');
    } finally {
      this.loading = false;
    }
  }
}
