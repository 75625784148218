import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { BaseResponse } from '../../../interfaces/base-response';
import { InvoiceNote } from '../../../interfaces/invoice-note';
import { AuthService } from '../../../services/auth.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';

@Component({
  selector: 'app-invoice-notes-tab',
  templateUrl: './invoice-notes-tab.component.html',
  styleUrls: ['./invoice-notes-tab.component.scss'],
})
export class InvoiceNotesTabComponent implements OnInit {
  @Input()
  invoiceId: number;
  @Input()
  userEmail: string;
  @Input()
  userName: string;

  notes = [];
  noteFilter = '';
  loading: boolean;

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      const notesResponse = await this.getNotes(this.invoiceId);
      this.notes = notesResponse ? notesResponse.items : null;
      this.sortNotes();
    } catch (err) {
      this.snackbar.showError('Error retrieving invoice notes');
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  constructor(
    private api: ApiService,
    private snackbar: SnackbarService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  getNotes(invoiceId: number): Promise<BaseResponse<InvoiceNote[]>> {
    return this.api.sendRequest<BaseResponse<InvoiceNote[]>>('GET', `/note/invoice/${invoiceId}/`).toPromise();
  }

  async addNote(): Promise<void> {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Add Note',
        addressType: '',
        formItems: [
          {
            slug: 'note',
            name: 'Note',
            required: true,
            type: AddFormItem.textarea,
            currentValue: '',
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.action === 'success') {
        const note: InvoiceNote = {
          archived_on: null,
          created_by: null,
          created_on: null,
          invoice_id_fk: this.invoiceId,
          last_updated_by: null,
          last_updated_on: null,
          note: result.data.note,
          note_id: null,
        };
        await this.createNote(note);
        const notesResponse = await this.getNotes(this.invoiceId);
        this.notes = notesResponse ? notesResponse.items : null;
        this.sortNotes();
      }
    });
  }

  sortNotes(): void {
    this.notes.sort((noteA, noteB) => {
      const dateA = new Date(noteA.created_on).getTime();
      const dateB = new Date(noteB.created_on).getTime();
      return dateB - dateA;
    });
  }

  async createNote(note: InvoiceNote): Promise<void> {
    try {
      await this.api.sendRequest<InvoiceNote>('POST', `/note/`, null, note).toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError(err.message);
    }
  }
}
