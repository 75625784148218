import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Customer } from 'src/app/interfaces/customer';
import { Service, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FileType } from '../../interfaces/invoice-file';

export interface FormOutput {
  customer_id: string;
  utility_provider_id: string;
  service_type: string;
  file_type: FileType;
}

class UploadFile {
  constructor(
    public file: File,
    public title: string,
    public type: string,
    public size: string,
    public fileUploadPercentage: number,
    public completed: boolean = false,
    public errors: string[] = [],
    public errorsOpen: boolean = false
  ) {
    this.fileUploadPercentage = 0;
  }

  onProgress(percentage: number): void {
    this.fileUploadPercentage = percentage;
  }
}

@Component({
  selector: 'app-upload-invoices',
  templateUrl: './upload-invoices.component.html',
  styleUrls: ['./upload-invoices.component.scss', '../common-page.scss'],
})
export class UploadInvoicesComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  uploadingFiles: UploadFile[] = [];
  uploadResults: { success: number; error: number } = null;
  pdfFileTypes: string[] = ['application/pdf'];
  excelFileTypes: string[] = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ];
  formData: FormOutput;
  fileForm: FormControl;
  customers: Customer[];
  customerUtilities: { [key: string]: Utility[] } = {};
  services: Service[] = Object.keys(Service).map((x) => Service[x]);
  uploading: boolean = false;
  draggedOver: boolean = false;
  interval: any;

  get canProcessFiles(): boolean {
    return !this.uploading && !this.uploadingFiles.some((x) => x.fileUploadPercentage !== 0);
  }

  constructor(private snackbarService: SnackbarService, private api: ApiService, private router: Router) {
    this.fileForm = new FormControl(null, [Validators.required]);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {}

  setFormData(formData: any): void {
    if (this.formData?.file_type !== formData?.file_type) {
      this.uploadingFiles = [];
    }

    this.formData = formData;
  }

  selectFile(_: any): void {
    const $el = document.createElement('input');
    $el.type = 'file';
    const fileTypes =
      this.formData.file_type === FileType.PDF ? this.pdfFileTypes.join(',') : this.excelFileTypes.join(',');
    $el.accept = fileTypes;
    $el.multiple = true;
    document.body.appendChild($el);
    $el.click();
    document.body.removeChild($el);
    const handleFiles = (_e: Event): void => {
      this.addFiles(Object.values($el.files));
      $el.removeEventListener('change', handleFiles);
    };
    $el.addEventListener('change', handleFiles);
  }

  clearFile(fileIndex: number): void {
    const fileToRemove = this.uploadingFiles[fileIndex];
    this.uploadingFiles = this.uploadingFiles.filter((x, i) => i !== fileIndex);
    if (fileToRemove.errors.length > 0) {
      this.uploadResults.error -= 1;
    }
    if (this.uploadingFiles.length === 0) {
      this.uploadResults = null;
    }
  }

  clear(): void {
    this.uploadingFiles = [];
    this.uploadResults = null;
  }

  preventDragDropDefault(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }

  dropped(e: any): void {
    this.preventDragDropDefault(e);
    this.draggedOver = false;

    if (!(e && e.dataTransfer && e.dataTransfer.items)) {
      this.snackbarService.showError('Error retrieving file.');
      return;
    }

    const items: File[] = Object.values(e.dataTransfer.files);
    const check = items.slice();

    let errorCount = 0;
    check.forEach((item) => {
      if (this.formData?.file_type === 'PDF') {
        if (this.pdfFileTypes.findIndex((value) => value === item.type) === -1) {
          items.splice(
            items.findIndex((file) => file === item),
            1
          );
          errorCount += 1;
        }
      } else {
        if (this.excelFileTypes.findIndex((value) => value === item.type) === -1) {
          items.splice(
            items.findIndex((file) => file === item),
            1
          );
          errorCount += 1;
        }
      }
    });

    if (errorCount > 0) {
      if (check.length === 1) {
        this.snackbarService.showError(`The file you uploaded had the wrong filetype.`);
      } else {
        this.snackbarService.showError(
          `${errorCount} of ${check.length} files had the wrong filetype and ${
            errorCount === 1 ? 'was' : 'were'
          } skipped.`
        );
      }
    }

    this.addFiles(items);
  }

  addFiles(items: File[]) {
    if (items.length === 0) {
      return;
    }
    let duplicates = false;
    [...this.uploadingFiles.map((x) => x.file), ...items].reduce((map, file) => {
      if (duplicates) {
        return map;
      }
      if (map[file.webkitRelativePath + file.name]) {
        duplicates = true;
      }
      map[file.webkitRelativePath + file.name] = true;
      return map;
    }, {});
    if (duplicates) {
      this.snackbarService.showError('Duplicate files');
      return;
    }
    this.uploadingFiles = [
      ...this.uploadingFiles,
      ...items.map((x) => {
        return new UploadFile(x, x.name, x.type, this.formatSize(x.size), 0);
      }),
    ];
  }

  async processFiles(): Promise<void> {
    this.uploadResults = null;

    try {
      const promises = [];
      this.uploading = true;
      for (const file of this.uploadingFiles) {
        if (file.fileUploadPercentage < 1) {
          promises.push(this.uploadFile(file));
        }
      }
      const results = await Promise.all(promises);
      this.uploadResults = {
        success: results.filter(Boolean).length,
        error: results.filter((x) => !x).length,
      };

      this.uploadingFiles = this.uploadingFiles.filter((x) => x.errors?.length > 0);
      if (this.uploadingFiles.length === 0) {
        this.router.navigate(['files']);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.uploading = false;
    }
  }

  async uploadFile(uploadFileItem: UploadFile): Promise<boolean> {
    let success = false;
    try {
      await this.api.uploadFile(
        this.formData.customer_id,
        this.formData.utility_provider_id,
        this.formData.service_type,
        this.formData.file_type,
        uploadFileItem.file,
        uploadFileItem.onProgress.bind(uploadFileItem)
      );
      success = true;
    } catch (err) {
      uploadFileItem.fileUploadPercentage = -1;
      uploadFileItem.errors = err?.error?.detail ? err?.error?.detail : ['Unknown error uploading file'];
    } finally {
      uploadFileItem.completed = true;
    }

    return success;
  }

  formatSize(size): string {
    const kbSize = 1024;
    const mbSize = kbSize * kbSize;
    if (size < mbSize) {
      return `${Math.round(size / kbSize)} KB`;
    }
    return `${Math.round(size / mbSize)} MB`;
  }

  cancel() {
    this.router.navigate(['files']);
  }
}
