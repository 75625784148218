import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { User } from '../interfaces/user';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<User> {
  constructor(private authService: AuthService) {}

  async resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<User> {
    return this.authService.getUser();
  }
}
