import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SearchReponseInvoice } from 'src/app/interfaces-search/search-response-invoice';
import { Address } from 'src/app/interfaces/address';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { Consumption } from 'src/app/interfaces/consumption';
import { EditableField } from 'src/app/interfaces/editable-field';
import { OcrMetadata } from 'src/app/interfaces/ocr-metadata';
import { ServiceAccountDisplay } from 'src/app/interfaces/service-account';
import { Usage } from 'src/app/interfaces/usage';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { InvoiceDataItem } from '../view-pdf.component';

@Component({
  selector: 'app-edit-invoice-form',
  templateUrl: './edit-invoice-form.component.html',
  styleUrls: ['./edit-invoice-form.component.scss'],
})
export class EditInvoiceFormComponent implements OnInit {
  @Input() invoice: SearchReponseInvoice;
  addressForm: FormGroup;
  usageForm: FormGroup;
  chargesForm: FormGroup;
  formItems: Map<string, EditableField>;
  consumptionFormItems: Map<string, EditableField>;
  invoiceEditDataTable: MatTableDataSource<InvoiceDataItem>;
  invoiceEditName: string = '';
  address: Address;
  usage: Usage;
  consumptions: Consumption[];
  invoiceMetadata: OcrMetadata;
  loading: boolean;

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      const serviceAccount = await this.getServiceAccount(this.invoice.service_account.account_id);
      this.address = await this.getAddress(serviceAccount.address_id_fk);

      const usageResponse = await this.getUsage(this.invoice.invoice_id);
      this.usage = usageResponse ? usageResponse.items[0] : null;

      const consumptionResponse = await this.getConsumption(this.invoice.invoice_id);
      this.consumptions = consumptionResponse ? consumptionResponse.items : null;
    } catch (err) {
      this.snackbar.showError('Error retrieving invoice data');
      console.log(err);
    } finally {
      this.generateFormItems();
      this.loading = false;
    }
  }

  constructor(private api: ApiService, private snackbar: SnackbarService) {}

  getServiceAccount(account_id: number): Promise<ServiceAccountDisplay> {
    try {
      return this.api.sendRequest<ServiceAccountDisplay>('GET', `/service_account/${account_id}/`).toPromise();
    } catch (err) {
      this.snackbar.showError('Error retrieving service account address');
      console.log(err);
    }
  }

  getAddress(address_id: number): Promise<Address> {
    try {
      return this.api.sendRequest<Address>('GET', `/address/${address_id}/`).toPromise();
    } catch (err) {
      this.snackbar.showError('Error retrieving service account address');
      console.log(err);
    }
  }

  getUsage(invoice_id: number): Promise<BaseResponse<Usage[]>> {
    try {
      return this.api.sendRequest<BaseResponse<Usage[]>>('GET', `/usage/invoice/${invoice_id}/`).toPromise();
    } catch (err) {
      this.snackbar.showError('Error retrieving usage for invoice');
      console.log(err);
    }
  }

  getConsumption(invoice_id: number): Promise<BaseResponse<Consumption[]>> {
    try {
      return this.api
        .sendRequest<BaseResponse<Consumption[]>>('GET', `/consumption/invoice/${invoice_id}/`)
        .toPromise();
    } catch (err) {
      this.snackbar.showError('Error retrieving consumption for invoice');
      console.log(err);
    }
  }

  generateFormItems(): void {
    if (this.consumptions === null || this.address === null || this.usage === null) {
      this.snackbar.showError('Error retrieving data');
      return;
    }

    let usage_meter_number = this.usage?.usage_meter_number;
    let invoice_total_amount = this.invoice?.invoice_total_amount;

    this.formItems = new Map<string, EditableField>();
    this.formItems.set('address_raw', {
      name: 'Street Address',
      edit: false,
      currentValue: this.address.address_raw,
      previousValue: this.address.address_raw,
    });
    this.formItems.set('address_city', {
      name: 'City',
      edit: false,
      currentValue: this.address.address_city,
      previousValue: this.address.address_city,
    });
    this.formItems.set('address_state', {
      name: 'State/Province',
      edit: false,
      currentValue: this.address.address_state,
      previousValue: this.address.address_state,
    });
    this.formItems.set('address_zip', {
      name: 'Zip',
      edit: false,
      currentValue: this.address.address_zip,
      previousValue: this.address.address_zip,
    });
    this.formItems.set('address_latitude', {
      name: 'Latitude',
      edit: false,
      currentValue: this.address.address_latitude,
      previousValue: this.address.address_latitude,
    });
    this.formItems.set('address_longitude', {
      name: 'Longitude',
      edit: false,
      currentValue: this.address.address_longitude,
      previousValue: this.address.address_longitude,
    });
    this.formItems.set('usage_meter_number', {
      name: 'Meter Number',
      edit: false,
      currentValue: usage_meter_number,
      previousValue: usage_meter_number,
    });
    this.formItems.set('invoice_total_amount', {
      name: 'Invoice Total Amount',
      edit: false,
      currentValue: invoice_total_amount,
      previousValue: invoice_total_amount,
    });

    this.addressForm = new FormGroup({
      address_raw: new FormControl(this.formItems.get('address_raw').currentValue),
      address_city: new FormControl(this.formItems.get('address_city').currentValue),
      address_state: new FormControl(this.formItems.get('address_state').currentValue),
      address_zip: new FormControl(this.formItems.get('address_zip').currentValue),
      address_latitude: new FormControl(
        this.formItems.get('address_latitude').currentValue,
        Validators.pattern('/^(s*|d+)$/')
      ),
      address_longitude: new FormControl(
        this.formItems.get('address_longitude').currentValue,
        Validators.pattern('/^(s*|d+)$/')
      ),
    });

    this.usageForm = new FormGroup({
      usage_meter_number: new FormControl(this.formItems.get('usage_meter_number').currentValue),
    });

    this.chargesForm = new FormGroup({
      invoice_total_amount: new FormControl(this.formItems.get('invoice_total_amount').currentValue),
    });

    this.consumptionFormItems = new Map<string, EditableField>();
    this.consumptions.forEach((consumption, index) => {
      const name = `consumption_read_${consumption.consumption_id}`;
      this.consumptionFormItems.set(name, {
        name: `Consumption Read ${index + 1}`,
        edit: false,
        currentValue: consumption.consumption_read,
        previousValue: consumption.consumption_read,
      });

      this.usageForm.addControl(name, new FormControl(consumption.consumption_read, Validators.pattern('/^(s*|d+)$/')));
    });
  }

  toggleEdit(key: string): void {
    const currentItem = this.formItems.get(key);
    currentItem.edit = !currentItem.edit;
    this.formItems.set(key, currentItem);
  }

  cancelEdit(key: string): void {
    this.toggleEdit(key);
    const originalValue = this.formItems.get(key);
    originalValue.currentValue = originalValue.previousValue;
    this.formItems.set(key, originalValue);
  }

  async submitAddressUpdate(key: string): Promise<void> {
    this.toggleEdit(key);
    const originalValue = this.formItems.get(key);
    originalValue.currentValue = this.addressForm.value[key];
    this.formItems.set(key, originalValue);
    const updatedAddress = this.address;

    updatedAddress[key] = this.addressForm.value[key];
    try {
      await this.updateAddress(updatedAddress);
    } catch (err) {
      this.snackbar.showError(
        `Error updating service account, ${this.formItems.get(key).name} can only contain numbers.`
      );
      originalValue.currentValue = originalValue.previousValue;
      this.addressForm.get(key).setValue(originalValue.previousValue);
    }
  }

  async submitMeterUpdate(key: string): Promise<void> {
    this.toggleEdit(key);
    const originalValue = this.formItems.get(key);
    originalValue.currentValue = this.usageForm.value[key];
    this.formItems.set(key, originalValue);
    const updatedUsage = this.usage;
    updatedUsage[key] = this.usageForm.value[key];
    try {
      await this.updateUsage(updatedUsage, this.invoice.invoice_id);
    } catch (err) {
      this.snackbar.showError(`Error updating data, ${this.formItems.get(key).name} can only contain numbers.`);
      originalValue.currentValue = originalValue.previousValue;
      this.usageForm.get(key).setValue(originalValue.previousValue);
      console.log(err);
    }
  }

  async submitChargesUpdate(key: string): Promise<void> {
    this.toggleEdit(key);
    const originalValue = this.formItems.get(key);
    originalValue.currentValue = this.chargesForm.value[key];
    this.formItems.set(key, originalValue);
    const total_amount = this.chargesForm.value[key];
    try {
      await this.updateCharges(this.invoice.invoice_id, key, total_amount);
    } catch (err) {
      this.snackbar.showError(`Error updating data, ${this.formItems.get(key).name} can only contain numbers.`);
      originalValue.currentValue = originalValue.previousValue;
      this.chargesForm.get(key).setValue(originalValue.previousValue);
      console.log(err);
    }
  }

  async submitConsumptionUpdate(key: string): Promise<void> {
    const originalValue = this.consumptionFormItems.get(key);
    originalValue.edit = false;
    originalValue.currentValue = this.usageForm.value[key];
    this.consumptionFormItems.set(key, originalValue);
    const updatedConsumption = this.consumptions.find((consumption) =>
      key.includes(consumption.consumption_id.toString())
    );

    updatedConsumption['consumption_read'] = this.usageForm.value[key];
    try {
      await this.updateConsumption(updatedConsumption, this.invoice.invoice_id);
    } catch (err) {
      this.snackbar.showError(
        `Error updating data, ${this.consumptionFormItems.get(key).name} can only contain numbers.`
      );
      originalValue.currentValue = originalValue.previousValue;
      this.usageForm.get(key).setValue(originalValue.previousValue);
      console.log(err);
    }
  }

  updateUsage(usage: Usage, invoice_id: number): Promise<Usage> {
    try {
      return this.api
        .sendRequest<Usage>('PUT', `/usage/${usage.usage_id}/invoice/${invoice_id}/`, null, usage)
        .toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating usage');
    }
  }

  updateConsumption(consumption: Consumption, invoice_id: number): Promise<Consumption> {
    try {
      return this.api
        .sendRequest<Consumption>(
          'PUT',
          `/consumption/${consumption.consumption_id}/invoice/${invoice_id}/`,
          null,
          consumption
        )
        .toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating consumption');
    }
  }

  updateCharges(
    invoice_id: number,
    key: string,
    value: number
  ): Promise<{ invoice_id: number; invoice_total_amount: number }> {
    try {
      let body = {
        invoice_total_amount: this.invoice.invoice_total_amount,
      };

      body[key] = value;

      return this.api
        .sendRequest<{ invoice_id: number; invoice_total_amount: number }>(
          'PUT',
          `/invoice/${invoice_id}/charges/`,
          null,
          body
        )
        .toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating invoice charges');
    }
  }

  updateAddress(address: Address): Promise<Address> {
    try {
      return this.api.sendRequest<Address>('PUT', `/address/${address.address_id}/`, null, address).toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating service account');
    }
  }
}
