import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-edit-invoice-item-state',
  templateUrl: './edit-invoice-item-state.component.html',
  styleUrls: ['./edit-invoice-item-state.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EditInvoiceItemStateComponent,
    },
  ],
})
export class EditInvoiceItemStateComponent implements OnInit, ControlValueAccessor {
  @Input()
  value: string;

  @Input()
  name: string;

  @Input()
  form: FormGroup;

  @Input()
  filter: string;

  @Output('onSubmit') onSubmit: EventEmitter<any> = new EventEmitter();

  stateList: string[] = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MP',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UM',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ];

  CAProvinces: string[] = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

  edit: boolean;
  touched = false;
  disabled = false;
  originalValue: string;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {
    this.edit = false;
    this.originalValue = this.value;
  }

  toggleEdit(): void {
    if (this.edit) {
      this.edit = false;
    } else {
      this.edit = true;
    }
  }

  cancelEdit(): void {
    this.edit = false;
    this.value = this.originalValue;
  }

  submitEdit(): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.onChange(this.value);
      this.originalValue = this.value;
      this.onSubmit.emit();
      this.edit = false;
    }
  }

  writeValue(value: string) {
    this.value = value;
    this.originalValue = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
