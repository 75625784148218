import { Component, Input, OnInit } from '@angular/core';
import { Service } from 'src/app/interfaces/utility';

@Component({
  selector: 'app-service-icon',
  templateUrl: './service-icon.component.html',
  styleUrls: ['./service-icon.component.scss'],
})
export class ServiceIconComponent implements OnInit {
  @Input() service: Service;
  @Input() deleteService: boolean = false;
  @Input() background: boolean = false;

  services = Service;

  constructor() {}

  ngOnInit(): void {}
}
