import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string = '';
  subtitle: string = '';
  message: string = '';
  successButton: string = 'Delete';

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.message = data.message;
    if (data.successButton) {
      this.successButton = data.successButton;
    }
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.close();
  }

  close(action = 'cancel') {
    this.dialogRef.close({ action });
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  success() {
    this.close('success');
  }
}
