import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export enum ErrorComponentErrorType {
  Unauthorized = 'Unauthorized',
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorType: ErrorComponentErrorType = null;
  ErrorComponentErrorType = ErrorComponentErrorType;

  constructor(private route: ActivatedRoute) {
    this.errorType = this.route.snapshot.queryParams?.errorType;
  }

  ngOnInit(): void {}
}
