import { InvoiceStatus } from './invoice.model';

export enum FileType {
  PDF = 'PDF',
  EXCEL = 'EXCEL',
}

export interface InvoiceFile {
  file_id: number;
  customer_id_fk: number;
  utility_id_fk: number;
  source_link: string;
  process_status: InvoiceStatus;
  start_time: string;
  end_time: string;
  file_type: FileType;
  created_by: string;
  created_on: string;
  archived_on: string;
  last_updated_on: string;
  last_updated_by: string;
  customer_name: string;
  utility_type: string;
  utility_name: string;
  ready_for_processing: boolean;
}
