import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { filter, map, throttleTime } from 'rxjs/operators';
import { Service } from '../interfaces/utility';
import { FileType } from '../interfaces/invoice-file';

interface QueryParams {
  [key: string]: boolean | string | number | number[] | undefined;
}

interface UploadStatus<T> {
  progress: number | null;
  response: T | null;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = '';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  sendRequest<T>(
    type: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    queryObject?: { [key: string]: any },
    data?: any
  ): Observable<T> {
    let observable: Observable<T>;
    const options = {};
    const headers = {};
    // NOTE: Can add headers here
    options['headers'] = headers;
    const query = queryObject ? this.queryStringify(queryObject) : '';

    switch (type) {
      case 'GET':
        observable = this.http.get<T>(`${this.apiUrl}${url}${query}`, options);
        break;
      case 'POST':
        observable = this.http.post<T>(`${this.apiUrl}${url}${query}`, data, options);
        break;
      case 'PUT':
        observable = this.http.put<T>(`${this.apiUrl}${url}${query}`, data, options);
        break;
      case 'DELETE':
        observable = this.http.delete<T>(`${this.apiUrl}${url}${query}`, options);
        break;
    }
    return observable;
  }

  sendFileRequest(url: string): Observable<any> {
    return this.http.get(`${this.apiUrl}${url}`, { responseType: 'blob' });
  }

  /**
   * Given a map of keys -> values, return a URL-sanitized query string
   * representing those keys and values. Keys with undefined values
   * are discarded.
   *
   * ```
   * queryStringify({
   *   notDefined: undefined,
   *   boolean: true,
   *   string: 'foo',
   *   unsafeString: 'foo&',
   *   number: 100,
   *   array: [1, 2, 3],
   * }) => '?boolean=true&string=foo&unsafeString=foo%26&number=100&array=1,2,3'
   * ```
   */
  private queryStringify(paramObj: QueryParams): string {
    const params = Object.entries(paramObj).filter(([, val]) => typeof val !== 'undefined');
    let queryString = '';
    let prefix = '?';

    for (const [key, _val] of params) {
      const val = Array.isArray(_val) ? _val.join(',') : encodeURIComponent(String(_val));
      if (!val) {
        continue;
      }
      queryString += `${prefix}${key}=${val}`;
      prefix = '&';
    }

    return queryString;
  }

  uploadFile(
    customer_id: string,
    utility_provider_id: string,
    service_type: string,
    file_type: FileType,
    file: File,
    onProgress: (progress: number) => void
  ): Promise<void> {
    const queryString = this.queryStringify({ customer_id, utility_provider_id, service_type });
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url =
      file_type === FileType.PDF
        ? `${this.apiUrl}/file/upload${queryString}`
        : `${this.apiUrl}/file/upload/excel${queryString}`;
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });
    return this.watchUploadProgress(req, onProgress);
  }

  // reports progress updates to `onProgress` and resolves to the API response
  private watchUploadProgress<T>(req: HttpRequest<any>, onProgress: (progress: number) => void): Promise<T> {
    // send initial progress value of 0
    onProgress(0);

    const request$ = new Subject<UploadStatus<T>>();

    this.http
      .request(req)
      .pipe(map((e) => this.getProgressFromEvent<T>(e)))
      .subscribe(request$);

    // send throttled update events to `onProgress` to update UI
    request$
      .pipe(
        filter((status) => status.progress !== null),
        throttleTime(300)
      )
      .subscribe((status) => onProgress(status.progress));

    // resolve with the final upload response
    return request$
      .pipe(
        filter((status) => status.response !== null),
        map((status) => status.response)
      )
      .toPromise();
  }

  private getProgressFromEvent<T>(event: HttpEvent<any>): UploadStatus<T> {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return {
          progress: Math.round((event.loaded * 100) / event.total),
          response: null,
        };
      case HttpEventType.Response:
        return {
          progress: event.status === 200 ? 100 : 0,
          response: event.body,
        };
      default:
        return {
          progress: null,
          response: null,
        };
    }
  }
}
