import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-display-error-dialog',
  templateUrl: './display-error-dialog.component.html',
  styleUrls: ['./display-error-dialog.component.scss'],
})
export class DisplayErrorDialogComponent implements OnInit {
  errorTable: MatTableDataSource<{ error_type: string; error_message: string }>;
  title: string = '';

  displayedColumns = ['error_type', 'error_message'];

  constructor(public dialogRef: MatDialogRef<DisplayErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.errorTable = new MatTableDataSource(data.errors);
    this.title = data.title;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.close();
  }

  close(action = 'cancel') {
    this.dialogRef.close({ action });
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  success() {
    this.close('success');
  }
}
