import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReplaySubject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { MatSort } from '@angular/material/sort';
import { EnergyRegionZipmap } from 'src/app/interfaces/epa-subregion-zipmap';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';

@Component({
  selector: 'app-energy-region-zipmap',
  templateUrl: './energy-region-zipmap.component.html',
  styleUrls: ['./energy-region-zipmap.component.scss'],
})
export class EnergyRegionZipmapComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  energyRegionZipmaps: MatTableDataSource<EnergyRegionZipmap>;
  loading: boolean = false;

  displayedColumns: string[] = ['zip', 'sr1', 'sr2', 'sr3', 'actions'];

  filter: SelectOption = {
    name: 'Search by zipcode',
    slug: 'zip',
    options: [],
    value: '',
    allowPartial: true,
    debounceInputs: true,
    type: SelectType.typeAhead,
  };

  constructor(private snackbar: SnackbarService, private api: ApiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.energyRegionZipmaps = new MatTableDataSource([]);
    this.getZipMaps();
  }

  ngAfterViewInit(): void {
    this.energyRegionZipmaps.paginator = this.paginator;
    this.energyRegionZipmaps.sort = this.sort;

    this.energyRegionZipmaps.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.energyRegionZipmaps.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getZipMaps(skip, take);
      }
    });
  }

  async getZipMaps(skip: number = 0, take: number = 100): Promise<void> {
    try {
      this.loading = true;

      const queryItems = { skip, take };
      if (this.filter.value !== '') {
        queryItems['zip'] = this.filter.value;
      }

      let { items, total = null } = await this.api
        .sendRequest<BaseResponse<EnergyRegionZipmap[]>>('GET', '/region_zip_map/', queryItems)
        .toPromise();
      if (total && total !== 0 && total !== null) {
        this.energyRegionZipmaps.paginator._intl = new CustomMatPaginatorIntl(total);
      }
      this.energyRegionZipmaps.data = skip > 0 ? this.energyRegionZipmaps.data.concat(items) : items;
    } catch (ex) {
      this.snackbar.showError(`Error while loading region zipmaps, ${ex}`);
    } finally {
      this.loading = false;
    }
  }

  async addZip(): Promise<void> {
    try {
      const dialogRef = this.dialog.open(AddItemDialogComponent, {
        width: '350px',
        data: {
          title: 'Add Zipcode Mapping',
          formItems: [
            {
              slug: 'zip',
              name: 'Zip Code',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'sr1',
              name: 'Subregion 1',
              required: true,
              type: AddFormItem.text,
            },
            {
              slug: 'sr2',
              name: 'Subregion 2',
              required: false,
              type: AddFormItem.text,
            },
            {
              slug: 'sr3',
              name: 'Subregion 3',
              required: false,
              type: AddFormItem.text,
            },
          ],
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.action === 'success') {
          this.postZip(result.data);
        }
      });
    } catch {
      this.snackbar.showError('Unknown error');
    }
  }

  async postZip(data: { zip: string; sr1: string; sr2: string; sr3: string }) {
    try {
      this.loading = true;

      for (let key in data) data[key] = data[key].length > 0 ? data[key] : null;

      await this.api.sendRequest<EnergyRegionZipmap>('POST', `/region_zip_map/`, null, data).toPromise();
      this.getZipMaps();
    } catch (ex) {
      this.snackbar.showError(ex.error.detail ?? 'Error creating zipmap');
    } finally {
      this.loading = false;
    }
  }

  async updateZip(currentZipMap: EnergyRegionZipmap) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '350px',
      data: {
        title: 'Add Zipcode Mapping',
        formItems: [
          {
            slug: 'zip',
            name: 'Zip Code',
            required: true,
            type: AddFormItem.text,
            currentValue: currentZipMap.zip,
          },
          {
            slug: 'sr1',
            name: 'Subregion 1',
            required: true,
            type: AddFormItem.text,
            currentValue: currentZipMap.sr1,
          },
          {
            slug: 'sr2',
            name: 'Subregion 2',
            required: false,
            type: AddFormItem.text,
            currentValue: currentZipMap.sr2,
          },
          {
            slug: 'sr3',
            name: 'Subregion 3',
            required: false,
            type: AddFormItem.text,
            currentValue: currentZipMap.sr3,
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.putZip(currentZipMap, result.data);
      }
    });
  }

  async putZip(zipmapOriginal: EnergyRegionZipmap, newData: { zip: string; sr1: string; sr2: string; sr3: string }) {
    Object.keys(newData).forEach((element) => {
      newData[element] = newData[element].length > 0 ? newData[element] : null;
    });

    try {
      this.loading = true;
      await this.api
        .sendRequest<EnergyRegionZipmap>('PUT', `/region_zip_map/${zipmapOriginal.id}/`, null, newData)
        .toPromise();
      this.getZipMaps();
    } catch (ex) {
      this.snackbar.showError(ex.error.detail ?? 'Error updating zipmap');
    } finally {
      this.loading = false;
    }
  }

  async removeZip(currentZipMap: EnergyRegionZipmap) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Zip Mapping',
        subtitle: currentZipMap.zip,
        message: `Are you sure you want to delete zipcode mapping ${currentZipMap.zip} (Id ${currentZipMap.id})?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteZip(currentZipMap);
      }
    });
  }

  async deleteZip(targetZipMap: EnergyRegionZipmap) {
    try {
      this.loading = true;
      await this.api.sendRequest<EnergyRegionZipmap>('DELETE', `/region_zip_map/${targetZipMap.id}/`).toPromise();
      this.getZipMaps();
    } catch (ex) {
      this.snackbar.showError('Unknown error deleting zipmap');
    } finally {
      this.loading = false;
    }
  }
}
