import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const match = value.match(/([^\/]+$)/);
    return match.length > 0 ? match[0] : '';
  }
}
