import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Service } from 'src/app/interfaces/utility';

enum AccountsPerFile {
  single = 'single',
  multiple = 'multiple',
}

@Component({
  selector: 'app-add-utility-provider-dialog',
  templateUrl: './add-utility-provider-dialog.component.html',
  styleUrls: ['./add-utility-provider-dialog.component.scss'],
})
export class AddUtilityProviderDialogComponent implements OnInit {
  addUtilityForm: FormGroup;
  accountsPerFile = AccountsPerFile;
  services: Service[] = [Service.electric, Service.gas, Service.water];

  constructor(
    public dialogRef: MatDialogRef<AddUtilityProviderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.addUtilityForm = new FormGroup({
      provider: new FormControl('', [Validators.required]),
      service: new FormControl('', [Validators.required]),
      accounts: new FormControl(false, [Validators.required]),
      includeSummary: new FormControl(false, [Validators.required]),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  async onSubmit() {
    console.log(this.addUtilityForm.getRawValue());
  }
}
