export interface TokenPayload {
  exp: number;
  user_id: number;
  user_name: string;
  user_role: UserRole;
}

export enum UserRole {
  admin = 'ADMIN',
  dataEntry = 'DATA_ENTRY',
  customer = 'CUSTOMER',
}

export enum UserRoleDisplay {
  ADMIN = 'Admin',
  DATA_ENTRY = 'Data Entry',
  CUSTOMER = 'Customer',
}
