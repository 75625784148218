import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { Service, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ChartFieldType, InvoiceFieldData, generateFormData, InvoiceFormRow } from '../../interfaces/invoice.model';

interface ChartGeneratationData {
  title: string;
  chartRows: InvoiceFieldData[][];
}

@Component({
  selector: 'app-edit-invoice-fields',
  templateUrl: './edit-invoice-fields.component.html',
  styleUrls: ['./edit-invoice-fields.component.scss'],
})
export class EditInvoiceFieldsComponent implements OnInit {
  loading: boolean = false;
  services: Service[] = [Service.electric, Service.gas];
  invoiceTitle = 'Electric Invoice fields';
  editInvoiceForm: FormGroup;
  formFieldsData: InvoiceFormRow[] = generateFormData;
  chartFieldType = ChartFieldType;
  formTypeLookup: { [key: string]: ChartFieldType } = {};
  FormArray = FormArray;
  utility: Utility;
  utilityId: string;

  get thirdPartyProvider(): boolean {
    return this.utility ? this.utility.utility_is_third_party : false;
  }

  get utilityAddress(): string {
    if (!this.utility) {
      return '';
    }
    return this.loading || !this.utility
      ? 'Loading'
      : `${this.utility.utility_address_street_address},
                                                         ${this.utility.utility_address_city},
                                                         ${this.utility.utility_address_state}
                                                         ${this.utility.utility_address_zip}`;
  }

  get title(): string {
    if (!this.utility) {
      return '';
    }
    return this.loading || !this.utility ? 'Loading' : this.utility.utility_name;
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private snackbar: SnackbarService
  ) {
    if (this.route.snapshot && this.route.snapshot.paramMap) {
      this.utilityId = this.route.snapshot.paramMap.get('utilityId');
    }
  }

  ngOnInit(): void {
    this.setUtilityData();
  }

  async setUtilityData() {
    try {
      this.loading = true;
      this.utility = await this.api.sendRequest<Utility>('GET', `/utility/${this.utilityId}/`).toPromise();
      if (!this.utility) {
        throw 'Utility does not exist';
      }
      if (!this.utility.utility_metadata) {
        this.utility.utility_metadata = this.getBaseUtilityProviderData();
      }
      this.buildForm();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving invoice data');
    } finally {
      this.loading = false;
    }
  }
  buildForm() {
    let group: { [key: string]: FormArray | FormControl } = {};
    this.formFieldsData.forEach((field) => {
      field.rows.forEach((row) => {
        row.forEach((field) => {
          const validators = field?.required ? [Validators.required] : [];
          this.formTypeLookup[field.slug] = field.type;
          const val =
            this.utility && this.utility.utility_metadata
              ? this.getProp(this.utility.utility_metadata, field.slug)
              : null;

          switch (field.type) {
            case ChartFieldType.array:
              const items = val && val[0] ? val : [''];
              const formArrayItems: FormControl[] = [];
              items.forEach((item) => {
                formArrayItems.push(new FormControl(item, validators));
              });
              group[field.slug] = new FormArray(formArrayItems);
              break;
            default:
              group[field.slug] = new FormControl(val ? val : '', validators);
          }
        });
      });
    });
    this.editInvoiceForm = new FormGroup(group);
  }

  addItemToFormArray(formArraySlug: string) {
    (this.editInvoiceForm.get(formArraySlug) as FormArray).push(new FormControl('', []));
  }

  removeItemFromFormArray(formArraySlug: string, i: number) {
    (this.editInvoiceForm.get(formArraySlug) as FormArray).removeAt(i);
  }

  async updateUtility() {
    try {
      this.loading = true;
      const metadata = this.getBaseUtilityProviderData();
      const formData = this.editInvoiceForm.getRawValue();
      Object.keys(formData).forEach((key) => {
        let keys = key.split('-');
        if (keys.length > 1) {
          metadata[keys[0]][keys[1]] = formData[key]
            ? formData[key]
            : this.getBaseUtilityProviderData()[keys[0]][keys[1]];
        } else {
          metadata[key] = formData[key] ? formData[key] : this.getBaseUtilityProviderData()[key];
        }
      });
      const data = { ...this.utility };
      if (data.utility_ready_for_processing) {
        data.utility_ready_for_processing = this.editInvoiceForm.valid;
      }
      data.utility_metadata = metadata;
      await this.api.sendRequest('PUT', `/utility/${this.utilityId}/`, null, data).toPromise();
      this.exit();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating utility');
    } finally {
      this.loading = false;
    }
  }

  editUtility() {
    const utility = this.utility;
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Update Utility',
        formItems: [
          {
            slug: 'utility_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: utility.utility_name,
          },
          {
            slug: 'utility_type',
            name: 'Utility Type',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(Service).map((x) => {
              return { value: Service[x], display: Service[x] };
            }),
            currentValue: utility.utility_type,
          },
          {
            slug: 'utility_is_third_party',
            name: 'Distribution Company',
            required: false,
            type: AddFormItem.checkbox,
            currentValue: utility.utility_is_third_party,
          },
          {
            slug: 'utility_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              item: utility,
              street_address: 'utility_address_street_address',
              state: 'utility_address_state',
              city: 'utility_address_city',
              zip: 'utility_address_zip',
            },
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.putUtility(result.data, utility);
      }
    });
  }

  async putUtility(
    data: {
      utility_name: string;
      utility_type: string;
      utility_is_third_party: string;
      utility_address_street_address: string;
      utility_address_state: string;
      utility_address_city: string;
      utility_address_zip: string;
    },
    utility: Utility
  ) {
    try {
      this.loading = true;
      const updateUtil = { ...utility };
      Object.keys(data).forEach((x) => {
        updateUtil[x] = data[x];
      });
      await this.api.sendRequest<Utility>('PUT', `/utility/${utility.utility_id}/`, null, updateUtil).toPromise();
      this.setUtilityData();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating utility');
    } finally {
      this.loading = false;
    }
  }

  getProp(obj, prop) {
    return prop.split('-').reduce((r, e) => {
      return r ? r[e] : null;
    }, obj);
  }

  exit() {
    this.router.navigate(['/invoice-fields', this.utility.utility_id]);
  }

  getBaseUtilityProviderData() {
    return {
      has_meter_table: false,
      has_charge_table: false,
      is_single_invoice: false,
      has_invoice_number: false,
      form_fields: {
        account_fields: [''],
        invoice_fields: [''],
        due_date_fields: [''],
        amount_due_fields: [''],
        prior_paid_fields: [''],
        unique_identifier: [''],
        new_charges_fields: [''],
        invoice_date_fields: [''],
        late_charges_fields: [''],
        prior_balance_fields: [''],
        invoice_amount_fields: [''],
        service_period_fields: [''],
        service_address_fields: [''],
        adjusted_charges_fields: [''],
        outstanding_balance_fields: [''],
      },
      usage_fields: {
        load_type: [''],
        read_type: [''],
        multiplier: [''],
        demand_name: [''],
        load_factor: [''],
        meter_number: [''],
        power_factor: [''],
        total_reading: [''],
        current_reading: [''],
        consumption_name: [''],
        previous_reading: [''],
        reading_difference: [''],
        conversion_multiplier: [''],
      },
      charge_fields: {
        charge_name: [''],
        charge_rate: [''],
        charge_amount: [''],
        charge_units_used: [''],
      },
    };
  }
}
