import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showError(description: string): void {
    this.snackBar.open(description, 'DISMISS', {
      panelClass: 'snackbar-error',
      duration: 10000,
    });
  }

  showInfo(description: string): void {
    this.snackBar.open(description, 'DISMISS', {
      panelClass: 'snackbar-info',
      duration: 10000,
    });
  }

  dismissSnackBar(): void {
    this.snackBar.dismiss();
  }
}
