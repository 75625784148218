import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserRole } from './interfaces/token-payload';
import { AdminGeneralControlComponent } from './pages/admin-general-control/admin-general-control.component';
import { AdminUserControlComponent } from './pages/admin-user-control/admin-user-control.component';
import { CustomerDetailComponent } from './pages/customer-detail/customer-detail.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { EditInvoiceFieldsComponent } from './pages/edit-invoice-fields/edit-invoice-fields.component';
import { FilesComponent } from './pages/files/files.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UploadInvoicesComponent } from './pages/upload-invoices/upload-invoices.component';
import { UtilityProvidersComponent } from './pages/utility-providers/utility-providers.component';
import { UtlityProviderDetailComponent } from './pages/utlity-provider-detail/utlity-provider-detail.component';
import { ViewPdfComponent } from './pages/view-pdf/view-pdf.component';
import { AuthGuard } from './services/auth-guard.service';
import { ErrorComponent } from './pages/error/error.component';
import { UserResolver } from './resolvers/user.resolver';

const routes: Routes = [
  {
    path: '',
    component: CustomerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'customer-detail/:customerId',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'view-invoice/:invoiceId',
    component: ViewPdfComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'upload-files',
    component: UploadInvoicesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'utility-providers',
    component: UtilityProvidersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-invoice/:utilityId',
    component: EditInvoiceFieldsComponent,
    canActivate: [AuthGuard],
    data: {
      role: UserRole.admin,
    },
  },
  {
    path: 'invoice-fields/:utilityId',
    component: UtlityProviderDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'files',
    component: FilesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice/:invoiceId',
    component: ViewPdfComponent,
    canActivate: [AuthGuard],
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: 'manage-users',
    component: AdminUserControlComponent,
    canActivate: [AuthGuard],
    data: {
      role: UserRole.admin,
    },
  },
  {
    path: 'admin-controls',
    component: AdminGeneralControlComponent,
    canActivate: [AuthGuard],
    data: {
      role: UserRole.admin,
    },
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
      //initialNavigation: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
