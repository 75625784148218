import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BottomSheetOptionsComponent } from './components/bottom-sheet-options/bottom-sheet-options.component';
import { SomeDialogComponent } from './components/some-dialog/some-dialog.component';
import { ServiceIconComponent } from './components/service-icon/service-icon.component';
import { FormDualSelectorComponent } from './components/form-dual-selector/form-dual-selector.component';
import { AddressFormItemComponent } from './components/address-form-item/address-form-item.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSelectServiceComponent } from './components/form-select-service/form-select-service.component';
import { InvoiceStatusComponent } from './components/invoice-status/invoice-status.component';
import { TypeAheadComponent } from './components/type-ahead/type-ahead.component';
import { SelectItemComponent } from './components/select-item/select-item.component';
import { TypeAheadAsyncComponent } from './components/type-ahead-async/type-ahead-async.component';

@NgModule({
  declarations: [
    BottomSheetOptionsComponent,
    SomeDialogComponent,
    ServiceIconComponent,
    FormDualSelectorComponent,
    AddressFormItemComponent,
    FormSelectServiceComponent,
    InvoiceStatusComponent,
    TypeAheadComponent,
    SelectItemComponent,
    TypeAheadAsyncComponent,
  ],
  exports: [
    ServiceIconComponent,
    FormDualSelectorComponent,
    AddressFormItemComponent,
    FormSelectServiceComponent,
    InvoiceStatusComponent,
    TypeAheadComponent,
    SelectItemComponent,
    TypeAheadAsyncComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ],
})
export class SharedModule {}
