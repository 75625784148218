import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './services/auth.service';
import { User } from './interfaces/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Notification } from './interfaces/notification';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  user: User = null;
  user$: Subscription;
  notifications: Notification[];

  get showHeader() {
    return !this.router.url.startsWith('pdf-viewer');
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  get loggedIn() {
    return this.authService.loggedIn;
  }

  get name() {
    return this.authService.name;
  }

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.user$ = this.authService.subscribeUser().subscribe((user) => {
      this.user = user;
    });
    this.generateNotifications();
  }

  nav(location: string) {
    switch (location) {
      case 'invoices':
        this.router.navigate(['invoices']);
        break;
      case 'files':
        this.router.navigate(['files']);
        break;
      case 'customers':
        this.router.navigate(['']);
        break;
      case 'utility':
        this.router.navigate(['utility-providers']);
        break;
      case 'manage-users':
        this.router.navigate(['manage-users']);
        break;
      case 'admin-controls':
        this.router.navigate(['admin-controls']);
        break;
      case 'profile':
        this.router.navigate(['profile']);
        break;
    }
  }

  ngOnDestroy() {
    if (this.user$) {
      this.user$.unsubscribe();
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  generateNotifications() {
    this.notifications = [];
    for (let i = 0; i < 100; i += 1) {
      this.notifications.push({
        title: this.getTitle(),
        fileName: `AB123456_mp2_gas_202${100 - i}.pdf`,
        read: Math.random() > 0.5,
        date: Date.now() - 7200000 * i,
      });
    }
  }

  getTitle() {
    const titles = ['Processing failed for', 'Processing completed with errors for'];
    let i = Math.floor(Math.random() * 2);
    return titles[i];
  }

  clickNotification() {}
}
