import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/interfaces/user';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { UserRole, UserRoleDisplay } from 'src/app/interfaces/token-payload';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';

enum FilterSlug {
  createdOn = 'created_on',
  lastUpdatedBy = 'last_updated_by',
  userEmail = 'user_email',
  createdBy = 'created_by',
  lastUpdatedOn = 'last_updated_on',
  userName = 'user_name',
  userId = 'user_id',
  userRole = 'user_role',
}

@Component({
  selector: 'app-admin-user-control',
  templateUrl: './admin-user-control.component.html',
  styleUrls: ['./admin-user-control.component.scss'],
})
export class AdminUserControlComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  UserRole = UserRole;
  FilterSlug = FilterSlug;
  displayedColumns: string[] = [
    // 'checked',
    'user_name',
    'user_email',
    'created_by',
    'created_on',
    'last_updated_by',
    'last_updated_on',
    'user_role',
    'actions',
  ];
  userTableSource: MatTableDataSource<User>;
  utilityProviders: string[] = [];
  allSelected: boolean = false;
  loading: boolean = false;
  interval: any = null;
  filterSelections: SelectOption[];
  includeArchived: boolean = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private snackbar: SnackbarService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userTableSource = new MatTableDataSource([]);
  }

  ngAfterViewInit(): void {
    const filter = this.getTableFilter();
    this.userTableSource.paginator = this.paginator;
    this.userTableSource.sort = this.sort;
    this.userTableSource.filterPredicate = filter;
    this.getUsers();
    this.userTableSource.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.userTableSource.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getUsers(skip, take);
      }
    });
    this.ref.detectChanges();
    this.filterSelections = [
      {
        name: 'Name',
        slug: FilterSlug.userName,
        options: [],
        value: '',
        allowPartial: false,
        type: SelectType.typeAhead,
      },
      {
        name: 'Email',
        slug: FilterSlug.userEmail,
        options: [],
        value: '',
        allowPartial: false,
        type: SelectType.typeAhead,
      },
      {
        name: 'User Role',
        slug: FilterSlug.userRole,
        value: '',
        options: Object.entries(UserRoleDisplay).map(([value, label]) => ({
          label,
          value,
        })),
        type: SelectType.select,
      },
    ];
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  changeArchived(includeArchived: boolean): void {
    this.includeArchived = includeArchived;
    this.getUsers();
  }

  getUserRoleDisplay(userRole: string): string {
    return UserRoleDisplay[userRole];
  }

  async getUsers(skip: number = 0, take: number = 100) {
    try {
      this.loading = true;
      const include_archived = this.includeArchived;
      let { items = [], total = null } = await this.api
        .sendRequest<BaseResponse<User[]>>('GET', `/auth/user/users`, { skip, take, include_archived })
        .toPromise();
      if (skip !== 0 && (!items || items.length === 0)) {
        return;
      }

      if (total && total !== 0 && total !== null) {
        this.userTableSource.paginator._intl = new CustomMatPaginatorIntl(total);
      }

      const data = this.userTableSource.data;
      this.userTableSource.data = skip > 0 ? data.concat(items) : items;
      this.updateFilters();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving users');
    } finally {
      this.loading = false;
      this.ref.detectChanges();
    }
  }

  getFilterSelection(slug: FilterSlug): SelectOption {
    return this.filterSelections.find((x) => x.slug === slug);
  }

  updateFilters() {
    const items: User[] = this.userTableSource.data;
    const customerNames: string[] = items.map((x) => x.user_name);
    const customerEmails: string[] = items.map((x) => x.user_email);
    this.filterSelections.find((x) => x.slug === FilterSlug.userName).options = customerNames
      .sort()
      .map((x) => ({ label: x, value: x }));
    this.filterSelections.find((x) => x.slug === FilterSlug.userEmail).options = customerEmails
      .sort()
      .map((x) => ({ label: x, value: x }));

    this.filterSelections.forEach((x) => (x = { ...x }));
    this.filterSelections = [...this.filterSelections];
  }

  getTableFilter(): (data: User, filter: string) => boolean {
    return (data, filter: string): boolean => {
      const filterObjects: { slug: string; value: string }[] = JSON.parse(filter);
      let valid = true;
      filterObjects.forEach((filter) => {
        valid =
          valid &&
          (filter.value.toLocaleLowerCase() === '' ||
            data[filter.slug].toLowerCase().includes(filter.value.toLowerCase()));
      });
      return valid;
    };
  }

  filterData() {
    const filterObject = this.filterSelections.map((x) => {
      return { slug: x.slug, value: x.value };
    });
    this.userTableSource.filter = JSON.stringify(filterObject);
  }

  addUser() {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Add new user',
        formItems: [
          {
            slug: FilterSlug.userName,
            name: 'Name',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: FilterSlug.userEmail,
            name: 'Email',
            required: true,
            type: AddFormItem.email,
          },
          {
            slug: 'user_password',
            name: 'Password',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: FilterSlug.userRole,
            name: 'User Role',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.entries(UserRoleDisplay).map(([value, display]) => ({
              display,
              value,
            })),
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.postUser(result.data);
      }
    });
  }

  updateUser(user: User) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Add new user',
        formItems: [
          {
            slug: FilterSlug.userName,
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: user.user_name,
          },
          {
            slug: FilterSlug.userEmail,
            name: 'Email',
            required: true,
            type: AddFormItem.email,
            currentValue: user.user_email,
          },
          {
            slug: FilterSlug.userRole,
            name: 'User Role',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.entries(UserRoleDisplay).map(([value, display]) => ({
              display,
              value,
            })),
            currentValue: user.user_role,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const newUser = { ...user };
      if (result.action === 'success') {
        newUser.user_email = result.data.user_email;
        newUser.user_name = result.data.user_name;
        newUser.user_role = result.data.user_role;
        this.putUser(newUser);
      }
    });
  }

  updateUserPassword(user: User) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Change password',
        subtitle: user.user_email,
        formItems: [
          {
            slug: 'user_password',
            name: 'Password',
            required: true,
            type: AddFormItem.text,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.changeUserPassword(user, result.data.user_password);
      }
    });
  }

  async changeUserPassword(user: User, password: string) {
    try {
      this.loading = true;
      user = await this.api
        .sendRequest<User>('PUT', `/auth/user/${user.user_id}/reset`, null, {
          new_password: password,
        })
        .toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error changing user password');
    } finally {
      this.snackbar.showInfo('User password successfully updated');
      this.loading = false;
    }
  }

  confirmDeleteUser(user: User) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Serice Account',
        subtitle: user.user_email,
        message: `Are you sure you want to delete ${user.user_name}'s account?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteUser(user);
      }
    });
  }

  async deleteUser(user: User) {
    try {
      this.loading = true;
      await this.api.sendRequest('DELETE', `/auth/user/${user.user_id}`).toPromise();
      this.getUsers();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error changing user password');
    } finally {
      this.snackbar.showInfo('User successfully deleted');
      this.loading = false;
    }
  }

  async putUser(user: User) {
    try {
      this.loading = true;
      await this.api
        .sendRequest<User>('PUT', `/auth/user/${user.user_id}`, null, {
          user_id: user.user_id,
          user_email: user.user_email,
          user_name: user.user_name,
          user_role: user.user_role,
        })
        .toPromise();
      let users = this.userTableSource.data;
      users = users.filter((x) => x.user_id !== user.user_id);
      users.push(user);
      this.userTableSource.data = [...users];

      if (this.authService.id == user.user_id) {
        this.authService.logout();
        this.router.navigate(['/login']);
        this.snackbar.showInfo('Please log back in to view changes');
      }
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updaing user data');
    } finally {
      this.loading = false;
    }
  }

  async postUser(data: { user_name: string; user_email: string; user_password: string; user_role: UserRole }) {
    try {
      this.loading = true;
      const user = await this.api.sendRequest<User>('POST', `/auth/user`, null, data).toPromise();
      this.getUsers();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error adding user');
    } finally {
      this.loading = false;
    }
  }
}
