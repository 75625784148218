import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { User } from 'src/app/interfaces/user';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', '../common-page.scss'],
})
export class ProfileComponent implements OnInit {
  loading: boolean = false;
  user: User;

  get name() {
    return this.authService.name;
  }

  get email() {
    if (this.loading) {
      return 'Loading...';
    }
    return this.user.user_email;
  }

  constructor(
    private authService: AuthService,
    private snackbar: SnackbarService,
    private api: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  async getUser() {
    try {
      this.loading = true;
      const user = await this.api.sendRequest<User>('GET', `/auth/user`).toPromise();
      if (user) {
        this.user = user;
      }
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving user info');
    } finally {
      this.loading = false;
    }
  }

  updateUserPassword() {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Change password',
        subtitle: this.user.user_email,
        formItems: [
          {
            slug: 'user_password',
            name: 'Password',
            required: true,
            type: AddFormItem.text,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.changeUserPassword(result.data.user_password);
      }
    });
  }

  async changeUserPassword(password: string) {
    try {
      this.loading = true;
      await this.api
        .sendRequest<User>('PUT', `/auth/user/${this.user.user_id}/reset`, null, {
          new_password: password,
        })
        .toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error changing password');
    } finally {
      this.snackbar.showInfo('Password successfully updated');
      this.loading = false;
    }
  }
}
