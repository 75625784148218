import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SearchCustomer } from 'src/app/interfaces-search/parts/search-customer';
import { SearchResponseCustomer } from 'src/app/interfaces-search/search-response-customer';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { Customer } from 'src/app/interfaces/customer';
import { Service, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FormOutput } from '../upload-invoices.component';

interface Option {
  value: string | Service;
  display: string;
  service?: Service;
  lookup?: string; // just for the customer_account_number
}

interface UploadValue {
  name: string;
  slug: FormSlug;
  options: Option[];
  filterOptions: Observable<string[]>;
  value: string;
  display: Function;
}

enum FormSlug {
  customer = 'customer_id',
  utilityType = 'service_type',
  utilityProvider = 'utility_provider_id',
  fileType = 'file_type',
}

@Component({
  selector: 'app-invoice-field-form',
  templateUrl: './invoice-field-form.component.html',
  styleUrls: ['./invoice-field-form.component.scss'],
})
export class InvoiceFieldFormComponent implements OnInit {
  @Output() formData: EventEmitter<FormOutput | string> = new EventEmitter();
  customerUtilities: { [key: string]: Utility[] } = {};
  loading: boolean = false;
  addInvoiceForm: FormGroup;
  selectItems: UploadValue[] = [
    {
      name: 'Customer',
      slug: FormSlug.customer,
      options: [],
      filterOptions: null,
      value: null,
      display: () => {
        return true;
      },
    },
    {
      name: 'Service',
      slug: FormSlug.utilityType,
      value: null,
      options: [],
      filterOptions: null,
      display: () => {
        return this.canDisplayService;
      },
    },
    {
      name: 'Utility Provider',
      slug: FormSlug.utilityProvider,
      options: [],
      value: null,
      filterOptions: null,
      display: () => {
        return this.canDisplayUtility;
      },
    },
  ];
  FormSlug = FormSlug;

  get canDisplayService() {
    if (this.loading || !this.addInvoiceForm.get(FormSlug.customer).value) {
      return false;
    }
    const options = this.selectItems
      ?.find((x) => x.slug === FormSlug.customer)
      ?.options.map((x) => x.display.toUpperCase());
    return !this.loading && options?.includes(this.addInvoiceForm.get(FormSlug.customer).value.toUpperCase());
  }

  get canDisplayUtility() {
    const data = this.addInvoiceForm?.getRawValue();
    return (
      data &&
      data[FormSlug.customer] &&
      data[FormSlug.utilityType] &&
      Object.values(Service).includes(data[FormSlug.utilityType])
    );
  }

  constructor(private snackbarService: SnackbarService, private api: ApiService) {}

  ngOnInit(): void {
    this.getCustomers();
    const group: { [key: string]: FormControl } = {};
    this.selectItems.forEach((item) => {
      const control = new FormControl(null, [Validators.required, this.validateUtility(item.slug)]);
      item.filterOptions = control.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this._filter(value, item.slug);
        })
      );
      group[item.slug] = control;
    });
    group[FormSlug.fileType] = new FormControl(null, [Validators.required]);
    this.addInvoiceForm = new FormGroup(group);
    this.addInvoiceForm.get(FormSlug.customer).statusChanges.subscribe((value) => {
      if (value === 'VALID') {
        const formValue = this.addInvoiceForm.get(FormSlug.customer).value.toLowerCase();
        const options = this.selectItems.find((x) => x.slug === FormSlug.customer).options;
        const selected = options.find((x) => x.display.toLowerCase() === formValue);
        this.getCustomerOptions(selected.value);
      } else {
        this.addInvoiceForm.get(FormSlug.utilityProvider).setValue(null); //resetting the values
        this.addInvoiceForm.get(FormSlug.utilityType).setValue(null);
        this.addInvoiceForm.markAsUntouched();
      }
    });
    this.addInvoiceForm.get(FormSlug.utilityType).valueChanges.subscribe((value) => {
      if (!value) {
        this.addInvoiceForm.get(FormSlug.utilityProvider).setValue(null);
      }
    });
    this.addInvoiceForm.statusChanges.subscribe((x) => {
      if (x === 'VALID') {
        this.sendFormData();
      } else {
        this.formData.next(null);
      }
    });
  }

  private _filter(value: string, slug: string): string[] {
    if (!value) {
      value = '';
    }
    const options = this.selectItems.find((x) => x.slug === slug).options;
    const baseFilter = options.filter((x) => x.display.toLowerCase().includes(value.toLowerCase()));
    if (slug !== 'utility_provider_id') {
      return baseFilter.map((x) => x.display);
    }
    return baseFilter
      .filter((x) => x.service === this.addInvoiceForm.get(FormSlug.utilityType).value)
      .map((x) => x.display);
  }

  validateUtility(slug: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !control.value) {
        return null;
      }
      const value = control.value;
      const options = this.selectItems.find((x) => x.slug === slug).options.map((x) => x.display.toLowerCase());
      if (!options.includes(value.toLowerCase())) {
        return { validationFailed: { message: 'Invalid selection' } };
      }
      return null;
    };
  }

  sendFormData() {
    const value = this.addInvoiceForm.getRawValue();
    const obj: FormOutput = Object.keys(FormSlug).reduce((o, x) => ({ ...o, [FormSlug[x]]: '' }), {}) as FormOutput;
    this.selectItems.forEach((item) => {
      const option = item.options.find((x) => x.display.toLowerCase() === value[item.slug].toLowerCase());
      obj[item.slug as string] = option.lookup ? option.lookup : option.value;
    });
    obj[FormSlug.fileType] = value[FormSlug.fileType];
    this.formData.next(obj);
  }

  async getCustomers() {
    try {
      this.loading = true;
      const { items } = await this.api
        .sendRequest<BaseResponse<SearchResponseCustomer[]>>('GET', '/customer/')
        .toPromise();
      const customers: Customer[] = items.map((x) => x.Customer);
      this.selectItems.find((x) => x.slug === FormSlug.customer).options = customers.map((x) => {
        return {
          lookup: x.customer_id?.toString(),
          value: x.customer_id?.toString(),
          display: x.customer_alias ? x.customer_alias : x.customer_name,
        };
      });
      this.triggerFormUpdate(FormSlug.customer);
    } catch (err) {
      this.snackbarService.showError('Error retrieving selection data');
    } finally {
      this.loading = false;
    }
  }

  async getCustomerOptions(customerId: string) {
    try {
      this.loading = true;
      const type = this.selectItems.find((x) => x.slug === FormSlug.utilityType);
      const provider = this.selectItems.find((x) => x.slug === FormSlug.utilityProvider);
      type.value = null; // resetting options
      type.options = [];
      provider.value = null;
      provider.options = [];
      if (!this.customerUtilities[customerId]) {
        // making a cache so i don't have to hit the server every time
        const { customer_utilities } = await this.api
          .sendRequest<{ customer_name: string; customer_utilities: Utility[] }>(
            'GET',
            `/utility/get_by_customer_id/${customerId}/`
          )
          .toPromise();

        customer_utilities.filter((x) => x.utility_ready_for_processing);
        this.customerUtilities[customerId] = [];
        customer_utilities.forEach((x) => {
          if (!this.customerUtilities[customerId].some((y) => y.utility_name === x.utility_name)) {
            this.customerUtilities[customerId].push(x);
          }
        });
      }
      const customer_utilities = this.customerUtilities[customerId];
      const typeMap: { [key: string]: { display: string; value: string } } = {}; // extracting all the types
      customer_utilities.forEach((item) => {
        if (item && item.utility_type) {
          typeMap[item.utility_type] = { display: item.utility_type, value: item.utility_type };
          provider.options.push({
            display: item.utility_name,
            value: item.utility_id.toString(),
            service: item.utility_type,
          });
        }
      });

      type.options = Object.values(typeMap);
      this.triggerFormUpdate(FormSlug.utilityProvider); //updating the utility autocomplete
      this.triggerFormUpdate(FormSlug.utilityType);
    } catch (err) {
      this.snackbarService.showError('Error retrieving customer utilities');
    } finally {
      this.loading = false;
    }
  }

  triggerFormUpdate(slug: FormSlug) {
    const formItem = this.addInvoiceForm.get(slug);
    formItem.setValue(formItem.value ? formItem.value : '');
  }
}
