import { ChangeBy } from './changeBy';
import { Service } from './utility';
import { FileType } from './invoice-file';

export enum InvoiceStatus {
  DB_INSERT_RUNNING = 'DB_INSERT_RUNNING',
  OCR_COMPLETE = 'OCR_COMPLETE',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  COMPLETE_WITH_ERRORS = 'COMPLETE_WITH_ERRORS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

export enum InvoiceStatusOption {
  PENDING = 'Pending',
  RUNNING = 'Processing',
  COMPLETE = 'Complete',
  COMPLETE_WITH_ERRORS = 'Complete with errors',
  ERROR = 'Error',
}

export interface Invoice {
  created_by: ChangeBy;
  created_on: string;
  archived_on: string;
  invoice_id: number;
  account_id_fk: number;
  file_id_fk: number;
  invoice_number: number;
  invoice_date: string;
  invoice_start_date: string;
  invoice_end_date: string;
  invoice_due_date: string;
  invoice_current_amount: number;
  invoice_previous_amount: number;
  invoice_total_consumption_amount: number;
  invoice_total_delivery_amount: number;
  invoice_total_tax_amount: number;
  invoice_total_miscellaneous_amount: null;
  invoice_total_amount: number;
  invoice_adjusted_amount: number | null;
  invoice_late_charge_amount: number | null;
  invoice_total_demand_read: number;
  invoice_total_consumption_read: number;
  invoice_total_reactive_read: null;
  invoice_source_link: string;
  invoice_check: boolean;
  invoice_review_status: ReviewStatus;
  invoice_review_status_updated_by: string | null;
  last_updated_on: string;
  last_updated_by: string;
}

export interface InvoiceWithData extends Invoice {
  customer_name: string;
  service_account_name: string;
  utility_type: Service;
  utility_name: string;
  last_process_time: string;
  process_status: InvoiceStatus;
  fields_expected: number;
  fields_extracted: number;
  source_link: string;
  file_type?: FileType;
}

export enum ChartFieldType {
  input = 'input',
  array = 'array',
  boolean = 'boolean',
  placeholder = 'placeholder',
}

export enum ReviewStatus {
  NA = 'NA',
  InReview = 'InReview',
  InReviewUser = 'InReviewUser',
  ReviewCompleted = 'ReviewCompleted',
}

export enum ReviewStatusFilter {
  NA = 'N/A',
  NeedsReview = 'Needs Review',
  ReviewCompleted = 'Reviewed',
}

export interface InvoiceFieldData {
  type: ChartFieldType;
  name: string;
  slug: string;
  required?: boolean;
}

export interface InvoiceFormRow {
  title: string;
  rows: InvoiceFieldData[][];
}

export const generateFormData: InvoiceFormRow[] = [
  {
    title: 'General',
    rows: [
      [
        {
          name: 'Unique Identifier',
          type: ChartFieldType.array,
          slug: 'form_fields-unique_identifier',
          required: true,
        },
        { name: 'Has Invoice Number', type: ChartFieldType.boolean, slug: 'has_invoice_number' },
        { name: 'Invoice Number Fields', type: ChartFieldType.array, slug: 'form_fields-invoice_fields' },
        { name: 'Account Fields', type: ChartFieldType.array, slug: 'form_fields-account_fields' },
      ],
      [
        { name: 'Invoice Date Fields', type: ChartFieldType.array, slug: 'form_fields-invoice_date_fields' },
        {
          name: 'Service Period Fields',
          type: ChartFieldType.array,
          slug: 'form_fields-service_period_fields',
          required: true,
        },
        { name: 'Due Date Fields', type: ChartFieldType.array, slug: 'form_fields-due_date_fields' },
        {
          name: 'Service Address Fields',
          type: ChartFieldType.array,
          slug: 'form_fields-service_address_fields',
          required: true,
        },
      ],
      [{ name: 'Is Single Invoice', type: ChartFieldType.boolean, slug: 'is_single_invoice' }],
    ],
  },
  {
    title: 'Billing',
    rows: [
      [
        { name: 'Ammount Due Fields:', type: ChartFieldType.array, slug: 'form_fields-amount_due_fields' },
        { name: 'Prior Balance Fields', type: ChartFieldType.array, slug: 'form_fields-prior_balance_fields' },
        { name: 'Prior Paid Fields', type: ChartFieldType.array, slug: 'form_fields-prior_paid_fields' },
        {
          name: 'Outstanding Balance Fields',
          type: ChartFieldType.array,
          slug: 'form_fields-outstanding_balance_fields',
        },
      ],
      [
        { name: 'Invoice Amount Fields', type: ChartFieldType.array, slug: 'form_fields-invoice_amount_fields' },
        { name: 'New Charges Fields', type: ChartFieldType.array, slug: 'form_fields-new_charges_fields' },
        { name: 'Late Charges Fields', type: ChartFieldType.array, slug: 'form_fields-late_charges_fields' }, // what is this
        { name: 'Adjusted Charges Fields', type: ChartFieldType.array, slug: 'form_fields-adjusted_charges_fields' },
      ],
      [
        { name: 'Has Charge Table', type: ChartFieldType.boolean, slug: 'has_charge_table' },
        { name: 'Charge Name', type: ChartFieldType.array, slug: 'charge_fields-charge_name' },
        { name: 'Charge Amount', type: ChartFieldType.array, slug: 'charge_fields-charge_amount' },
        { name: 'Demand Name', type: ChartFieldType.array, slug: 'usage_fields-demand_name' },
      ],
      [
        { name: 'Charge Units Used', type: ChartFieldType.array, slug: 'charge_fields-charge_units_used' },
        { name: 'Charge Rate', type: ChartFieldType.array, slug: 'charge_fields-charge_rate' },
      ],
    ],
  },
  {
    title: 'Usage',
    rows: [
      [
        { name: 'Load Type', type: ChartFieldType.array, slug: 'usage_fields-load_type' },
        { name: 'Usage Name', type: ChartFieldType.array, slug: 'usage_fields-consumption_name' },
        { name: 'Read Type', type: ChartFieldType.array, slug: 'usage_fields-read_type' },
        { name: 'Current Reading', type: ChartFieldType.array, slug: 'usage_fields-current_reading' },
      ],
      [
        { name: 'Previous Reading', type: ChartFieldType.array, slug: 'usage_fields-previous_reading' },
        { name: 'Reading Difference', type: ChartFieldType.array, slug: 'usage_fields-reading_difference' },
        { name: 'Total Reading', type: ChartFieldType.array, slug: 'usage_fields-total_reading' },
        { name: 'Load Factor', type: ChartFieldType.array, slug: 'usage_fields-load_factor' },
      ],
      [
        { name: 'Power Factor', type: ChartFieldType.array, slug: 'usage_fields-power_factor' },
        { name: 'Has Meter Table', type: ChartFieldType.boolean, slug: 'has_meter_table' },
        { name: 'Meter Number', type: ChartFieldType.array, slug: 'usage_fields-meter_number' },
        { name: 'Multiplier', type: ChartFieldType.array, slug: 'usage_fields-multiplier' },
      ],
      [{ name: 'Conversion Multiplier', type: ChartFieldType.array, slug: 'usage_fields-conversion_multiplier' }],
    ],
  },
];
