import { Component, Input, OnInit } from '@angular/core';
import { InvoiceNote } from '../../../../interfaces/invoice-note';
import { Usage } from '../../../../interfaces/usage';
import { ApiService } from '../../../../services/api.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../interfaces/user';

@Component({
  selector: 'app-invoice-notes-tab-item',
  templateUrl: './invoice-notes-tab-item.component.html',
  styleUrls: ['./invoice-notes-tab-item.component.scss'],
})
export class InvoiceNotesTabItemComponent implements OnInit {
  @Input()
  item: InvoiceNote;

  @Input()
  filter: string;

  @Input()
  userEmail: string;

  loading: boolean = false;
  edit = false;
  originalValue: string;
  noteEmail: string;
  noteUsername: string;
  date: string;
  canEdit: boolean = true;

  constructor(private api: ApiService, private snackbar: SnackbarService, private authService: AuthService) {}

  ngOnInit(): void {
    this.originalValue = this.item?.note;
    this.noteEmail = this.item?.created_by?.split(' ')[0];
    this.noteUsername = this.item?.user_name;
    const dateParser = new Date(this.item?.created_on);
    this.date = `${dateParser.getMonth()}/${dateParser.getDay()}/${dateParser.getFullYear()} ${dateParser.getTime()}`;
    this.canEdit = this.noteEmail === this.userEmail;
  }

  getRowHeight(): number {
    const length = this.item?.note?.split('\n').length;
    if (length > 0) return length;
    if (length > 5) return 5;
    return 1;
  }

  toggleEdit(): void {
    this.edit = !this.edit;
  }

  cancelEdit(): void {
    this.edit = false;
    this.item.note = this.originalValue;
  }

  async submitEdit(): Promise<void> {
    this.item.note = this.item.note.trim();
    this.originalValue = this.item.note;
    await this.updateNote(this.item);
    this.toggleEdit();
  }

  async updateNote(note: InvoiceNote): Promise<void> {
    try {
      await this.api.sendRequest<InvoiceNote>('PUT', `/note/${note.note_id}/`, null, note).toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating note');
    }
  }
}
