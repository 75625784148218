import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Service, ServiceFilterOption, Utility } from 'src/app/interfaces/utility';
import {
  Invoice,
  InvoiceStatus,
  InvoiceStatusOption,
  InvoiceWithData,
  ReviewStatus,
  ReviewStatusFilter,
} from 'src/app/interfaces/invoice.model';
import { ApiService } from 'src/app/services/api.service';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchReponseInvoice } from 'src/app/interfaces-search/search-response-invoice';
import { SearchResponseCustomer } from 'src/app/interfaces-search/search-response-customer';
import { FileType } from '../../interfaces/invoice-file';
import { CustomMatPaginatorIntl } from 'src/app/paginator.class';

enum FilterSlug {
  customer = 'customer_name',
  reviewStatus = 'invoice_review_status',
  fileType = 'file_type',
  utility = 'utility_name',
  utilityType = 'utility_type',
  status = 'process_status',
  source = 'source_link',
  expected = 'fields_expected',
  extracted = 'fields_extracted',
}

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  FilterSlug = FilterSlug;
  FileType = FileType;
  ReviewStatus = ReviewStatus;
  InvoiceStatus = InvoiceStatus;

  displayedColumns: string[] = [
    'source_link',
    'invoice_review_status',
    'file_type',
    'customer_name',
    'utility_name',
    'utility_type',
    'invoice_number',
    'last_process_time',
    'fields_extracted',
    'process_status',
  ];
  SelectType = SelectType;
  InvoiceStatusOption = InvoiceStatusOption;
  services: ServiceFilterOption[] = Object.keys(ServiceFilterOption).map((x) => ServiceFilterOption[x]);
  service: string = ServiceFilterOption.all;
  invoiceTableSource: MatTableDataSource<InvoiceWithData>;
  utilityProviders: string[] = [];
  allSelected: boolean = false;
  loading: boolean;
  intervalTime: number = 10000;
  interval: any = null;
  filterSelections: SelectOption[] = [
    {
      name: 'Customer',
      slug: 'customer_id_fk',
      options: [],
      value: '',
      allowPartial: false,
      debounceInputs: true,
      type: SelectType.typeAheadAsync,
      updateFunction: async (filterSelection: SelectOption) => {
        const obj = { take: 10 };
        if (filterSelection.labelInputValue) {
          obj['customer_name'] = filterSelection.labelInputValue;
        }
        const { items = [] } = await this.api
          .sendRequest<BaseResponse<SearchResponseCustomer[]>>('GET', '/customer/', obj)
          .toPromise();
        filterSelection.options = items.map((x) => {
          return {
            label: x.Customer.customer_name,
            value: x.Customer.customer_id,
          };
        });
      },
    },
    {
      name: 'File Type',
      slug: FilterSlug.fileType,
      value: '',
      options: Object.entries(FileType).map(([value, label]) => ({
        label,
        value,
      })),
      type: SelectType.select,
      allowPartial: false,
    },
    {
      name: 'Utility Provider',
      slug: 'utility_id_fk',
      options: [],
      value: '',
      allowPartial: false,
      debounceInputs: true,
      type: SelectType.typeAheadAsync,
      updateFunction: async (filterSelection: SelectOption) => {
        const obj = { take: 10 };
        if (filterSelection.labelInputValue) {
          obj['utility_name'] = filterSelection.labelInputValue;
        }
        const { items = [] } = await this.api.sendRequest<BaseResponse<Utility[]>>('GET', '/utility/', obj).toPromise();
        filterSelection.options = items.map((x) => {
          return {
            label: x.utility_name,
            value: x.utility_id,
          };
        });
      },
    },
    {
      name: 'Invoice Status',
      slug: 'process_status',
      value: '',
      options: Object.entries(InvoiceStatusOption).map(([value, label]) => ({
        label,
        value,
      })),
      type: SelectType.typeAhead,
      allowPartial: false,
    },
    {
      name: 'Review Status',
      slug: FilterSlug.reviewStatus,
      value: '',
      options: Object.entries(ReviewStatusFilter).map(([value, label]) => ({
        label,
        value,
      })),
      type: SelectType.typeAhead,
      allowPartial: false,
    },
    {
      name: 'Invoice Number',
      slug: 'invoice_number',
      value: '',
      options: [],
      allowPartial: true,
      type: SelectType.typeAhead,
      debounceInputs: true,
    },
  ];

  constructor(private router: Router, private api: ApiService, private snackbar: SnackbarService) {}

  ngOnInit(): void {
    this.invoiceTableSource = new MatTableDataSource([]);
    this.getInvoices();
  }

  ngAfterViewInit(): void {
    const filter = this.getTableFilter();
    this.invoiceTableSource.paginator = this.paginator;
    this.invoiceTableSource.sort = this.sort;
    this.invoiceTableSource.filterPredicate = filter;
    this.pollInvoices();
    this.invoiceTableSource.paginator.page.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x.pageIndex === this.paginator.getNumberOfPages() - 1) {
        const skip = this.invoiceTableSource.data.length;
        const take = Math.floor(this.paginator.pageSize * 1.5);
        this.getInvoices(skip, take);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async getInvoices(skip: number = 0, take: number = 100) {
    try {
      this.loading = true;
      const obj = { skip, take };
      this.filterSelections &&
        this.filterSelections.forEach((filter) => {
          if (filter.value) {
            obj[filter.slug] = filter.value;
          }
        });
      let { items = [], total = null } = await this.api
        .sendRequest<BaseResponse<SearchReponseInvoice[]>>('GET', `/invoice/detailed`, obj)
        .toPromise();

      if (total && total !== 0 && total !== null) {
        this.invoiceTableSource.paginator._intl = new CustomMatPaginatorIntl(total);
      }

      if (skip !== 0 && (!items || items.length === 0)) {
        return;
      }
      const invoices: InvoiceWithData[] = items.map((x) => {
        const copy = { ...x };
        delete copy.service_account;
        delete copy.file;
        delete copy.ocr_metric;
        const baseInvoice: Invoice = copy;
        return {
          ...baseInvoice,
          customer_name: x?.service_account?.customer?.customer_name,
          service_account_name: x?.service_account?.account_name,
          utility_type: x?.service_account?.utility?.utility_type,
          utility_name: x?.service_account?.utility?.utility_name,
          last_process_time: x?.file?.start_time,
          source_link: x?.file?.source_link,
          invoice_review_status: x?.invoice_review_status,
          file_type: x?.file?.file_type,
          process_status: x?.file?.process_status,
          fields_expected: x?.ocr_metric?.fields_expected,
          fields_extracted: x?.ocr_metric?.fields_extracted,
        };
      });
      const data = this.invoiceTableSource.data;
      this.invoiceTableSource.data = skip > 0 ? data.concat(invoices) : invoices;
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving invoices');
    } finally {
      this.loading = false;
    }
  }

  getFilterSelection(slug: FilterSlug): SelectOption {
    return this.filterSelections.find((x) => x.slug === slug);
  }

  getAsciiMapValue(s: string) {
    //to make sure the values are valid map values
    return 'key' + [...s].map((x) => x.charCodeAt(0)).reduce((a, b) => a + b);
  }

  getTableFilter(): (data: InvoiceWithData, filter: string) => boolean {
    return (data, filter: string): boolean => {
      const filterObjects: { slug: string; value: string }[] = JSON.parse(filter);
      let valid = true;
      filterObjects.forEach((filter) => {
        if (
          filter.slug === FilterSlug.status &&
          [
            InvoiceStatus.RUNNING,
            InvoiceStatus.PENDING,
            InvoiceStatus.OCR_COMPLETE,
            InvoiceStatus.DB_INSERT_RUNNING,
          ].includes(filter.value as InvoiceStatus)
        ) {
          filter.value = InvoiceStatus.RUNNING;
        }
        valid =
          valid &&
          (filter.value.toLowerCase() === '' || data[filter.slug].toLowerCase().includes(filter.value.toLowerCase()));
      });
      return valid;
    };
  }

  async getFile(invoice: InvoiceWithData) {
    try {
      this.loading = true;
      let fileLink = await this.api.sendRequest<string>('GET', `/file/${invoice.file_id_fk}`).toPromise();
      window.open(fileLink, '_blank');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error generating download link');
    } finally {
      this.loading = false;
    }
  }

  async reprocess(invoice: InvoiceWithData) {
    try {
      this.loading = true;
      await this.api.sendRequest('POST', `/invoice/${invoice.invoice_id}/reprocess`).toPromise();
      invoice.process_status = InvoiceStatus.PENDING;
      this.snackbar.showInfo('Invoice reprocessing');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error reprocessing invoice');
    } finally {
      this.loading = false;
    }
  }

  goToInvoice(invoice: InvoiceWithData) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/invoice/${invoice.invoice_id}`]));
    window.open(url, '_blank');
  }

  pollInvoices() {
    this.interval = setInterval(() => {
      this.getStatus();
    }, this.intervalTime);
  }

  async getStatus() {
    try {
      this.loading = true;
      const invoices = this.invoiceTableSource.data.filter((x) =>
        [InvoiceStatus.RUNNING, InvoiceStatus.PENDING, InvoiceStatus.DB_INSERT_RUNNING, InvoiceStatus].includes(
          x.process_status
        )
      );
      if (invoices.length === 0) {
        this.loading = false;
        return;
      }
      let file_ids = invoices
        .filter((x, i) => {
          return invoices.findIndex((y) => y.file_id_fk === x.file_id_fk) === i;
        })
        .map((x) => x.file_id_fk)
        .join(',');
      const items = await this.api
        .sendRequest<{ file_id: number; process_status: InvoiceStatus }[]>(
          'GET',
          `/file/status/batch?file_ids=${file_ids}`
        )
        .toPromise();
      const data = this.invoiceTableSource.data;
      items.forEach((item) => {
        const row = data.find((x) => x.file_id_fk === item.file_id);
        if (row) {
          row.process_status = item.process_status;
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  filterData() {
    this.getInvoices();
  }
  /*
  uploadInvoices() {
    this.router.navigate(['upload-invoices']);
  }
  */
}
