export enum Service {
  electric = 'ELECTRICITY',
  gas = 'GAS',
  water = 'WATER',
}

export enum ServiceFilterOption {
  all = 'ALL',
  electric = 'ELECTRICITY',
  gas = 'GAS',
  water = 'WATER',
}

export interface Utility {
  created_by: string;
  created_on: string;
  archived_on: string;
  last_updated_on: string;
  last_updated_by: string;
  utility_id: number;
  utility_name: string;
  utility_address_street_address: string;
  utility_address_state: string;
  utility_address_city: string;
  utility_address_zip: string;
  utility_type: Service;
  utility_start_date: string;
  utility_end_date: string;
  utility_is_third_party: boolean;
  utility_metadata: UtilityMetadata;
  utility_ready_for_processing: boolean;
  utility_epa_subregion_id_fk: number;
}

export interface UtilityMetadata {
  has_meter_table: boolean;
  has_charge_table: boolean;
  is_single_invoice: boolean;
  has_invoice_number: boolean;
  form_fields: {
    account_fields: string[];
    invoice_fields: string[];
    due_date_fields: string[];
    amount_due_fields: string[];
    prior_paid_fields: string[];
    unique_identifier: string[];
    new_charges_fields: string[];
    invoice_date_fields: string[];
    late_charges_fields: string[];
    prior_balance_fields: string[];
    invoice_amount_fields: string[];
    service_period_fields: string[];
    service_address_fields: string[];
    adjusted_charges_fields: string[];
    outstanding_balance_fields: string[];
  };
  usage_fields: {
    load_type: string[];
    read_type: string[];
    multiplier: string[];
    demand_name: string[];
    load_factor: string[];
    meter_number: string[];
    power_factor: string[];
    total_reading: string[];
    current_reading: string[];
    consumption_name: string[];
    previous_reading: string[];
    reading_difference: string[];
    conversion_multiplier: string[];
  };
  charge_fields: {
    charge_name: string[];
    charge_rate: string[];
    charge_amount: string[];
    charge_units_used: string[];
  };
}
