import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Service } from 'src/app/interfaces/utility';

@Component({
  selector: 'app-form-select-service',
  templateUrl: './form-select-service.component.html',
  styleUrls: ['./form-select-service.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormSelectServiceComponent,
    },
  ],
})
export class FormSelectServiceComponent implements OnInit, ControlValueAccessor {
  @Input() value: Service[] = [];

  @Input() fieldTitle: string;

  services = Object.keys(Service).map((x) => Service[x]);

  onChange = (quantity) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  get unincludedServices() {
    if (!this.value) {
      return this.services;
    }
    return this.services.filter((x) => !this.value.includes(x));
  }

  get floatLabel() {
    if (!this.value || this.value.length === 0) {
      return 'auto';
    }
    return 'always';
  }

  constructor() {}

  ngOnInit(): void {}

  onAddService(service: Service) {
    if (this.disabled) {
      return;
    }
    if (!this.value.includes(service)) {
      this.value.push(service);
    }
    this.onChange(this.value);
  }

  onRemoveService(service: Service) {
    if (this.disabled) {
      return;
    }
    this.value = this.value.filter((x) => x !== service);
    this.onChange(this.value);
  }

  writeValue(value: Service[]): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
