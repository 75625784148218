import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { EPASubregion } from 'src/app/interfaces/epa-subregions';
import { Service, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { InvoiceFormRow, generateFormData, ChartFieldType } from '../../interfaces/invoice.model';

@Component({
  selector: 'app-utlity-provider-detail',
  templateUrl: './utlity-provider-detail.component.html',
  styleUrls: ['./utlity-provider-detail.component.scss'],
})
export class UtlityProviderDetailComponent implements OnInit {
  services: Service[] = [Service.electric, Service.gas, Service.water];
  epaSubregions: EPASubregion[];

  readyForProcessing: boolean = false;
  chartFieldType = ChartFieldType;
  utility: Utility;
  invoiceFieldsData: InvoiceFormRow[] = generateFormData;
  utilityId: string;
  loadingUtility: boolean = false;
  loadingSubregions: boolean = false;
  utilityRegion: EPASubregion;

  get loading(): boolean {
    return this.loadingUtility || this.loadingSubregions;
  }

  canEditReadyForProcessing: boolean = false;

  get thirdPartyProvider(): boolean {
    return this.utility ? this.utility.utility_is_third_party : false;
  }

  get utilityAddress(): string {
    if (!this.utility) {
      return '';
    }

    if (this.loading) {
      return 'Loading';
    }

    const address_values = [];
    if (this.utility.utility_address_street_address !== null && this.utility.utility_address_street_address !== '') {
      address_values.push(this.utility.utility_address_street_address);
    }

    if (this.utility.utility_address_city !== null && this.utility.utility_address_city !== '') {
      address_values.push(this.utility.utility_address_city);
    }

    if (this.utility.utility_address_state !== null && this.utility.utility_address_state !== '') {
      address_values.push(this.utility.utility_address_state);
    }

    if (this.utility.utility_address_zip !== null && this.utility.utility_address_zip !== '') {
      address_values.push(this.utility.utility_address_zip);
    }

    if (address_values.length === 0) return 'N/A';
    if (address_values.length === 1) return address_values[0];

    let result = '';

    for (let index = 0; index < address_values.length - 1; index++) {
      result = result.concat(`${address_values[index]}, `);
    }

    result = result.concat(address_values[address_values.length - 1]);

    return result;
  }

  get utilityEpaSubregion(): string {
    if (!this.utility || !this.epaSubregions) {
      return '';
    }

    if (this.loading) {
      return 'Loading';
    }

    return !this.utilityRegion ? 'N/A' : `${this.utilityRegion.name}`;
  }

  get invoiceTitle() {
    return this.loading || !this.utility ? 'Loading' : `${this.utility.utility_type} Invoice Fields`;
  }

  get title(): string {
    if (!this.utility) {
      return '';
    }
    return this.loading || !this.utility ? 'Loading' : this.utility.utility_name;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  get canEdit(): boolean {
    return this.isAdmin && this.utility && this.utility.archived_on == null;
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private snackbar: SnackbarService,
    private authService: AuthService
  ) {
    if (this.route.snapshot && this.route.snapshot.paramMap) {
      this.utilityId = this.route.snapshot.paramMap.get('utilityId');
    }
  }

  async ngOnInit(): Promise<void> {
    await this.setEpaSubregions();
    await this.setUtilityData();
  }

  async setEpaSubregions(): Promise<void> {
    try {
      this.loadingSubregions = true;
      const result = await this.api.sendRequest<BaseResponse<EPASubregion[]>>('GET', `/epa_subregion/`).toPromise();
      if (!result || !result.items) {
        throw new Error('Unable to load EPA Subregions');
      }
      this.epaSubregions = result.items;
    } catch (err) {
      this.snackbar.showError('Error retrieving current EPA Subregions');
    } finally {
      this.loadingSubregions = false;
    }
  }

  async setUtilityData(): Promise<void> {
    try {
      this.loadingUtility = true;
      this.utility = await this.api.sendRequest<Utility>('GET', `/utility/${this.utilityId}/`).toPromise();
      if (!this.utility) {
        throw 'Utility does not exist';
      }
      if (!this.utility.utility_metadata) {
        this.utility.utility_metadata = this.getBaseUtilityProviderData();
      }
      const requiredColumns = [];
      this.invoiceFieldsData.forEach((section) => {
        section.rows.forEach((row) => {
          row.forEach((item) => {
            if (item.required) {
              requiredColumns.push(item.slug);
            }
          });
        });
      });
      let items = 0;
      requiredColumns.forEach((column) => {
        const item = this.getProp(this.utility.utility_metadata, column);
        if (item && Array.isArray(item) && item[0] && item[0] !== '') {
          items += 1;
        } else if (item && !Array.isArray(item) && item !== '') {
          items += 1;
        }
      });
      if (items === requiredColumns.length) {
        this.canEditReadyForProcessing = true;
      }
      if (this.utility.utility_epa_subregion_id_fk) {
        this.utilityRegion = this.epaSubregions.find((r) => r.id === this.utility.utility_epa_subregion_id_fk);
      }
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving utility data');
    } finally {
      this.loadingUtility = false;
    }
  }

  async toggleReadyForProcessing(utility_ready_for_processing: boolean) {
    if (!this.canEditReadyForProcessing) {
      return;
    }
    try {
      this.loadingUtility = true;
      const data = { ...this.utility };
      data.utility_ready_for_processing = utility_ready_for_processing;
      this.utility = await this.api
        .sendRequest<Utility>('PUT', `/utility/${this.utility.utility_id}/`, null, data)
        .toPromise();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error updating utility');
    } finally {
      this.loadingUtility = false;
    }
  }

  updateUtlityProviderDialog() {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Edit Utility Provider',
        subtitle: this.utility.utility_name,
        addressType: 'Remit To',
        formItems: [
          {
            slug: 'utility_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: this.utility.utility_name,
          },
          {
            slug: 'utility_type',
            name: 'Utility Type',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(Service).map((x) => {
              return { value: Service[x], display: Service[x] };
            }),
            currentValue: this.utility.utility_type,
          },
          {
            slug: 'utility_is_third_party',
            name: 'Distribution Company',
            required: false,
            type: AddFormItem.checkbox,
            currentValue: this.utility.utility_is_third_party,
          },
          {
            slug: 'utility_start_date',
            name: 'Utility Start Date',
            type: AddFormItem.date,
            currentValue: this.utility.utility_start_date,
          },
          {
            slug: 'utility_end_date',
            name: 'Utility End Date',
            type: AddFormItem.date,
            currentValue: this.utility.utility_end_date,
          },
          {
            slug: 'utility_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              item: this.utility,
              street_address: 'utility_address_street_address',
              state: 'utility_address_state',
              city: 'utility_address_city',
              zip: 'utility_address_zip',
            },
          },
          {
            slug: 'utility_epa_subregion_id_fk',
            name: 'EPA Subregion',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(this.epaSubregions).map((x) => {
              return { value: this.epaSubregions[x].id, display: this.epaSubregions[x].name };
            }),
            currentValue: this.utility.utility_epa_subregion_id_fk,
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.updateUtility(result.data);
      }
    });
  }

  async updateUtility(data: {
    utility_name: string;
    utility_type: string;
    utility_start_date: string;
    utility_end_date: string;
    utility_address_street_address: string;
    utility_address_state: string;
    utility_address_city: string;
    utility_address_zip: string;
    utility_is_third_party: boolean;
    utility_epa_subregion_id_fk: number | string;
  }) {
    try {
      this.loadingUtility = true;
      if (data.utility_epa_subregion_id_fk === '' || data.utility_epa_subregion_id_fk === null) {
        delete data.utility_epa_subregion_id_fk;
      }
      const updateUtil = { ...this.utility };
      Object.keys(data).forEach((x) => {
        updateUtil[x] = data[x];
      });
      this.utility = await this.api
        .sendRequest<Utility>('PUT', `/utility/${this.utility.utility_id}/`, null, updateUtil)
        .toPromise();

      if (this.utility.utility_epa_subregion_id_fk) {
        this.utilityRegion = this.epaSubregions.find((r) => r.id === this.utility.utility_epa_subregion_id_fk);
      }
      this.snackbar.showInfo('Utility provider updated');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error editing utility');
    } finally {
      this.loadingUtility = false;
    }
  }

  confirmDelete() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Utility Provider',
        subtitle: this.utility.utility_name,
        message: `Are you sure you want to delete ${this.utility.utility_name}?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.deleteUtility(this.utility);
      }
    });
  }

  async deleteUtility(utility: Utility) {
    try {
      this.loadingUtility = true;
      await this.api.sendRequest<Utility>('DELETE', `/utility/${utility.utility_id}/`).toPromise();
      this.router.navigate(['utility-providers']);
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error deleting utility');
    } finally {
      this.loadingUtility = false;
    }
  }

  editInvoiceFields() {
    this.router.navigate(['edit-invoice', this.utilityId]);
  }

  getProp(obj, prop) {
    if (!obj) {
      return null;
    }
    const o = prop.split('-').reduce((r, e) => {
      return r ? r[e] : null;
    }, obj);
    return o;
  }

  getBaseUtilityProviderData() {
    return {
      has_meter_table: false,
      has_charge_table: false,
      is_single_invoice: false,
      has_invoice_number: false,
      form_fields: {
        account_fields: [''],
        invoice_fields: [''],
        due_date_fields: [''],
        amount_due_fields: [''],
        prior_paid_fields: [''],
        unique_identifier: [''],
        new_charges_fields: [''],
        invoice_date_fields: [''],
        late_charges_fields: [''],
        prior_balance_fields: [''],
        invoice_amount_fields: [''],
        service_period_fields: [''],
        service_address_fields: [''],
        adjusted_charges_fields: [''],
        outstanding_balance_fields: [''],
      },
      usage_fields: {
        load_type: [''],
        read_type: [''],
        multiplier: [''],
        demand_name: [''],
        load_factor: [''],
        meter_number: [''],
        power_factor: [''],
        total_reading: [''],
        current_reading: [''],
        consumption_name: [''],
        previous_reading: [''],
        reading_difference: [''],
        conversion_multiplier: [''],
      },
      charge_fields: {
        charge_name: [''],
        charge_rate: [''],
        charge_amount: [''],
        charge_units_used: [''],
      },
    };
  }
}
