import { Component, Input, OnInit } from '@angular/core';
import { InvoiceStatus } from 'src/app/interfaces/invoice.model';

@Component({
  selector: 'app-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss'],
})
export class InvoiceStatusComponent implements OnInit {
  @Input() status: InvoiceStatus;

  invoiceStatus = InvoiceStatus;

  constructor() {}

  ngOnInit(): void {}
}
