export enum SelectType {
  typeAhead = 'type-ahead',
  select = 'select',
  typeAheadAsync = 'type-ahead-async',
}

export interface SelectOption {
  name: string;
  slug: string;
  type: SelectType;
  // options: string[];
  options?: {
    label: string;
    value: any;
  }[];
  value: string;
  labelInputValue?: string;
  allowPartial?: boolean;
  debounceInputs?: boolean;
  updateFunction?: Function;
}
