import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { Customer } from 'src/app/interfaces/customer';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss', '../common-page.scss'],
})
export class CustomerDetailComponent implements OnInit {
  customerId: string;
  loading: boolean = false;
  loadingUtilityProviders: boolean = false;
  loadingServiceAccountsTable: boolean = false;

  customer: Customer;

  get customerAddress(): string {
    return this.loading || !this.customer
      ? 'Loading'
      : `${this.customer.customer_address_street_address},
                                                         ${this.customer.customer_address_city},
                                                         ${this.customer.customer_address_state}
                                                         ${this.customer.customer_address_zip}`;
  }

  get name(): string {
    if (this.loading || !this.customer) {
      return 'Loading...';
    }
    return this.customer.customer_alias ? this.customer.customer_alias : this.customer.customer_name;
  }

  get accountNumber(): string {
    if (this.loading || !this.customer) {
      return 'Loading...';
    }
    return this.customer.customer_account_number;
  }

  get isLoadingState() {
    return this.loading || this.loadingUtilityProviders || this.loadingServiceAccountsTable;
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private authService: AuthService,
    private router: Router,
    private api: ApiService
  ) {
    if (this.route.snapshot && this.route.snapshot.paramMap) {
      this.customerId = this.route.snapshot.paramMap.get('customerId');
    }
  }

  ngOnInit(): void {
    this.getCustomer();
  }

  async getCustomer() {
    try {
      this.loading = true;
      this.customer = await this.api.sendRequest<Customer>('GET', `/customer/${this.customerId}/`).toPromise();
    } catch (err) {
      this.snackbar.showError('Error retrieving customer');
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  editCustomer() {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',
      data: {
        title: 'Edit Customer',
        addressType: 'Billing',
        formItems: [
          {
            slug: 'customer_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
            currentValue: this.customer.customer_name,
          },
          {
            slug: 'customer_alias',
            name: 'Alias',
            required: false,
            type: AddFormItem.text,
            currentValue: this.customer.customer_alias,
          },
          {
            slug: 'customer_account_number',
            name: 'Customer Acct. No.',
            required: true,
            type: AddFormItem.text,
            currentValue: this.customer.customer_account_number,
          },
          {
            slug: 'customer_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              item: this.customer,
              street_address: 'customer_address_street_address',
              state: 'customer_address_state',
              city: 'customer_address_city',
              zip: 'customer_address_zip',
            },
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.putCustomer(result.data, this.customer);
      }
    });
  }

  async putCustomer(
    customerData: {
      customer_name: string;
      customer_alias: string;
      customer_acccount_number: string;
      customer_address_street_address: string;
      customer_address_state: string;
      customer_address_city: string;
      customer_address_zip: string;
    },
    customer: Customer
  ) {
    try {
      this.loading = true;
      await this.api.sendRequest('PUT', `/customer/${customer.customer_id}/`, null, customerData).toPromise();
      this.snackbar.showInfo('Customer successfully updated');
      this.getCustomer();
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error creating customers');
    } finally {
      this.loading = false;
    }
  }
}
