import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export interface BottomSheetOptionsComponentData {
  title: string;
  options: BottomSheetOption[];
}

export interface BottomSheetOption {
  line1: string;
  line2?: string;
  data: any;
}

@Component({
  selector: 'app-bottom-sheet-options',
  templateUrl: './bottom-sheet-options.component.html',
  styleUrls: ['./bottom-sheet-options.component.scss'],
})
export class BottomSheetOptionsComponent implements OnInit {
  constructor(
    private bottomSheet: MatBottomSheetRef<BottomSheetOptionsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetOptionsComponentData
  ) {}

  ngOnInit() {}

  optionClicked(option: BottomSheetOption): void {
    this.bottomSheet.dismiss(option.data);
  }
}
