import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../services/snackbar.service';
import { filter } from 'rxjs/operators';
import { UserRole } from '../interfaces/token-payload';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  loadingUser = false;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.subscribeLoadingUser().subscribe((loadingUser) => {
      this.loadingUser = loadingUser;
    });
  }

  async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authService.loggedIn && !(_route.pathFromRoot.join('/') === '/login')) {
      this.router.navigate(['login']);
    }
    if (_route?.data?.role === UserRole.admin) {
      return this.authService.isAdmin;
    }
    return this.authService.loggedIn;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
