import { stringify } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormItem, AddFormItem } from 'src/app/interfaces/dialog-form-data';

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.scss'],
})
export class AddItemDialogComponent implements OnInit {
  form: FormGroup;
  title: string;
  subtitle: string;
  formItems: FormItem[];
  addFormItem = AddFormItem;
  streetAddressSlug: string;
  addressKeys = null;
  addressType: string;

  constructor(public dialogRef: MatDialogRef<AddItemDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.title = data.title;
    this.subtitle = data.subtitle ? data.subtitle : '';
    this.formItems = data.formItems;
    this.addressType = data.addressType ? data.addressType : '';
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm() {
    let group: { [key: string]: FormControl } = {};
    this.formItems.forEach((item) => {
      const validators = item.required ? [Validators.required] : [];
      const currentValue = item.currentValue ? item.currentValue : '';
      switch (item.type) {
        case AddFormItem.address:
          this.addressKeys = currentValue;
          const splitStreetAddress = this.splitAddressLines(
            currentValue.item ? currentValue.item[currentValue.street_address] : ''
          ); // breaking out in line 1 and line 2
          group.address_line_1 = new FormControl(splitStreetAddress.line1, [Validators.required]);
          group.address_line_2 = new FormControl(splitStreetAddress.line2);
          group[currentValue.city] = new FormControl(currentValue.item ? currentValue.item[currentValue.city] : '', [
            Validators.required,
          ]);
          group[currentValue.state] = new FormControl(currentValue.item ? currentValue.item[currentValue.state] : '', [
            Validators.required,
          ]);
          group[currentValue.zip] = new FormControl(currentValue.item ? currentValue.item[currentValue.zip] : '', [
            Validators.required,
          ]);
          break;
        case AddFormItem.number:
          group[item.slug] = new FormControl(currentValue && currentValue !== '' ? currentValue : null, [
            ...validators,
            // Regex for numbers or empty
            Validators.pattern('/^(s*|d+)$/'),
          ]);
          break;
        case AddFormItem.textarea:
          group[item.slug] = new FormControl(currentValue && currentValue !== '' ? currentValue : null, [
            ...validators,
          ]);
          break;
        case AddFormItem.email:
          group[item.slug] = new FormControl(currentValue ? currentValue : '', [...validators, Validators.email]);
          break;
        case AddFormItem.services:
          group[item.slug] = new FormControl(currentValue ? currentValue : [], validators);
          break;
        case AddFormItem.checkbox:
          group[item.slug] = new FormControl(currentValue ? true : false, validators);
          break;
        default:
          group[item.slug] = new FormControl(currentValue, validators);
          break;
      }
    });
    this.form = new FormGroup(group);
  }

  splitAddressLines(streetAddress: string): { line1: string; line2: string } {
    if (!streetAddress) {
      return { line1: '', line2: '' };
    }
    const result = streetAddress.match(/([^,]+)/g);
    const line1 = result[0];
    const line2 = result[1] ? result[1].substring(1) : '';
    return { line1, line2 };
  }

  onNoClick(): void {
    this.dialogRef.close({ action: 'cancel' });
  }

  close() {
    this.dialogRef.close({ action: 'cancel' });
  }

  async onSubmit() {
    this.dialogRef.close({ action: 'success', data: this.getFormData() });
  }

  getFormData() {
    const data = this.form.getRawValue();
    if (!data.address_line_1) {
      return data;
    }

    const { address_line_1, address_line_2, ...formData } = data;
    if (address_line_2) {
      formData[this.addressKeys.street_address] = `${address_line_1}, ${address_line_2}`;
    } else {
      formData[this.addressKeys.street_address] = `${address_line_1}`;
    }

    if (this.addressKeys.raw) {
      formData[this.addressKeys.raw] = `${formData[this.addressKeys.street_address]}, ${
        formData[this.addressKeys.city]
      },
                                        ${formData[this.addressKeys.state]} ${formData[this.addressKeys.zip]}`;
    }
    this.formItems.forEach((item) => {
      // replacing the "" form dates with nulls for the api;
      if (item.type === this.addFormItem.date || item.type === this.addFormItem.number) {
        formData[item.slug] = formData[item.slug] ? formData[item.slug] : null;
      }
    });
    return formData;
  }
}
