import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { interval, Observable } from 'rxjs';
import { debounce, map, scan, startWith } from 'rxjs/operators';
import { SelectOption } from 'src/app/interfaces/select-option';

@Component({
  selector: 'app-type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.scss'],
})
export class TypeAheadComponent implements OnInit {
  @Input() selectOption: SelectOption;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  formGroup: FormGroup;
  currentValue: string = '';
  //   = new FormControl();
  get filteredOptions(): string[] {
    if (this.selectOption?.options?.length === 0) {
      return [];
    }
    if (!this.currentValue || this.currentValue === '') {
      return this.selectOption.options.map((x) => x.label);
    }
    return this.selectOption.options
      .filter((x) => x.label.toLowerCase().includes(this.currentValue.toLowerCase()))
      .map((x) => x.label);
  }

  constructor() {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      typeAheadControl: new FormControl(),
    });
    const control = this.formGroup.get('typeAheadControl');
    const allowPartial = this.selectOption?.allowPartial ? true : false;
    const debounceInputs = this.selectOption?.debounceInputs ? true : false;
    control.valueChanges.pipe(debounce(() => interval(debounceInputs ? 600 : 0))).subscribe((value) => {
      this.currentValue = value;
      if (allowPartial) {
        this.selectOption.value = value ? value : '';
        this.valueChange.next();
        return;
      }
      const lowerCase = value.toLowerCase();
      const option = this.selectOption.options.find((x) => x.label.toLowerCase() === lowerCase);
      if (option) {
        this.selectOption.value = option.value;
        this.valueChange.next();
      } else {
        this.selectOption.value = '';
        this.valueChange.next();
      }
    });
  }
}
