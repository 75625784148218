import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
})
export class SelectItemComponent implements OnInit {
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() selectOption: SelectOption;

  SelectType = SelectType;
  constructor() {}

  ngOnInit(): void {}
}
