import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { User } from '../../interfaces/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loggingIn = false;
  user: User = null;
  loading = false;
  windowRef: any;
  loginForm: FormGroup;
  error: string = '';

  constructor(private authService: AuthService, private snackbarService: SnackbarService, private router: Router) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }
  /*
  getErrorMessage() {
    return this.email.hasError('required')
      ? 'You must enter a value'
      : this.email.hasError('email')
      ? 'Not a valid email'
      : '';
  }
  */

  async login(): Promise<void> {
    try {
      this.loading = true;
      // await setTimeout(() => {}, 1000);
      const formData = await this.loginForm.getRawValue();
      await this.authService.login(formData.username, formData.password);
      if (this.authService.loggedIn) {
        this.snackbarService.dismissSnackBar();
        this.router.navigate(['']);
      } else {
        this.error = 'Unable to log in. Please check your credentials and try again.';
      }
    } catch (err) {
      this.snackbarService.showError(err.error.detail);
    } finally {
      this.loading = false;
    }

    // TODO: Maybe await the above call and trycatch
  }

  goHome(): void {
    this.router.navigate(['/']);
  }
}
