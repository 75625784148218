import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/login')) {
      return next.handle(request);
    } else if (this.authService._token) {
      return next.handle(this.updateHeader(request)).pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.router.navigate(['/login']);
            this.authService.logout();
          }
          return throwError(error);
        })
      );
    }
  }

  updateHeader(request) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService._token}`,
      },
    });
    return request;
  }
}
