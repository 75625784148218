import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TokenPayload, UserRole } from '../interfaces/token-payload';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { ErrorComponentErrorType } from '../pages/error/error.component';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly LOCAL_STORAGE_ACCESS_TOKEN = 'legend.accesstoken';
  readonly SESSION_STORAGE_ORIGINAL_URL = 'legend.originalurl';
  private apiUrl: string;
  private user: BehaviorSubject<any> = new BehaviorSubject(null);
  private loadedUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loadingUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private token: BehaviorSubject<string> = new BehaviorSubject(null);
  tokenPayload: TokenPayload = null;

  get loggedIn(): boolean {
    return this.token.value ? true : false;
  }

  get loginValid(): boolean {
    if (this.tokenPayload !== null) {
      return this.tokenPayload.exp * 1000 < Date.now();
    }
  }

  get _token() {
    return this.token.value;
  }

  get name() {
    if (!this.tokenPayload) {
      return '';
    }
    return this.tokenPayload.user_name;
  }

  get id() {
    if (!this.tokenPayload) {
      return null;
    }
    return this.tokenPayload.user_id;
  }

  get isAdmin() {
    if (!this.tokenPayload) {
      return false;
    }
    return this.tokenPayload.user_role === UserRole.admin;
  }

  constructor(private http: HttpClient, private router: Router) {
    this.apiUrl = environment.apiUrl;
    this.init();
  }

  public init(): void {
    let token = localStorage.getItem(this.LOCAL_STORAGE_ACCESS_TOKEN);
    if (!token) {
      return;
    }
    this.tokenPayload = jwt_decode(token) as TokenPayload;
    if (this.tokenPayload.exp * 1000 < Date.now()) {
      this.logout();
    }
    this.token.next(token);
  }

  public setToken(token: string): void {
    this.token.next(token);
    localStorage.setItem(this.LOCAL_STORAGE_ACCESS_TOKEN, token);
  }

  public async getUser(): Promise<any> {
    const accessToken: string = this.token.getValue();
    try {
      this.loadingUser.next(true);
      const userResult = await this.http
        .get<User>(`${this.apiUrl}/auth/user`, { headers: { authorization: `Bearer ${accessToken}` } })
        .toPromise();
      this.user.next(userResult);
      this.user.complete();
      return userResult;
    } catch (err) {
      console.log(err?.error?.detail ? err?.error?.detail : 'Error retrieving user');
    } finally {
      this.loadingUser.next(false);
      this.loadedUser.next(true);
    }
  }

  public async login(email: string, password: string): Promise<boolean> {
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);
    const response = await this.http
      .post<{ access_token: string; token_type: string }>(`${this.apiUrl}/auth/login`, formData)
      .toPromise();
    if (response.access_token) {
      this.nextToken(response.access_token);

      if (this.tokenPayload?.user_role === UserRole.customer) {
        this.clear();
        this.router.navigate(['/', 'error'], {
          queryParams: {
            errorType: ErrorComponentErrorType.Unauthorized,
          },
        });
        return false;
      }
      await this.getUser();

      return true;
    }

    return false;
  }

  public logout(): void {
    this.clear();
  }

  public subscribeUser(): Observable<any> {
    return this.user.asObservable();
  }

  public subscribeToken(): Observable<string> {
    return this.token.asObservable();
  }

  public subscribeLoadedUser(): Observable<boolean> {
    return this.loadedUser.asObservable();
  }

  public subscribeLoadingUser(): Observable<boolean> {
    return this.loadingUser.asObservable();
  }

  public nextToken(token: string): void {
    localStorage.setItem(this.LOCAL_STORAGE_ACCESS_TOKEN, token);
    this.tokenPayload = jwt_decode(token) as TokenPayload;
    if (this.tokenPayload.exp * 1000 < Date.now()) {
      this.logout();
      return;
    }
    return this.token.next(token);
  }

  public removeLogoutToken(): void {
    this.token.next(null);
  }

  public clear(): void {
    this.user.next(null);
    this.token.next(null);
    this.loadedUser.next(false);
    this.loadingUser.next(false);
    this.tokenPayload = null;
    localStorage.removeItem(this.LOCAL_STORAGE_ACCESS_TOKEN);
  }
}
