import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchReponseInvoice } from 'src/app/interfaces-search/search-response-invoice';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { FileType } from 'src/app/interfaces/invoice-file';
import { OcrMetadata } from 'src/app/interfaces/ocr-metadata';
import { User } from 'src/app/interfaces/user';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { environment } from '../../../environments/environment';
import { ReviewStatus } from '../../interfaces/invoice.model';

export interface InvoiceDataItem {
  title: string;
  value: string;
  confidence: number;
  bbox: number[];
  pageNumber: number;
}

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfComponent implements OnInit, OnDestroy {
  invoiceId: string;
  loading: boolean = true;
  metaData: OcrMetadata;
  invoice: SearchReponseInvoice;
  loadingInvoice: boolean = false;
  pdfObject: any;
  fileDownloadLink: string = '';
  links = ['TEXT RECOGNITION', 'MANUAL OVERRIDES'];
  FileType = FileType;
  ReviewStatus = ReviewStatus;
  reviewDropdownClass$: BehaviorSubject<string>;
  user$: Subscription = null;
  user: User = null;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  get invoiceUrl() {
    return `${environment.apiUrl}/invoice/${this.invoiceId}/file/processed`;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarService,
    private api: ApiService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    if (this.route.snapshot && this.route.snapshot.paramMap) {
      this.invoiceId = this.route.snapshot.paramMap.get('invoiceId');
    }
    this.pdfObject = {
      url: this.invoiceUrl,
      httpHeaders: { Authorization: `Bearer ${this.authService._token}` },
      withCredentials: true,
    };
  }

  async ngOnInit(): Promise<void> {
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe(
      ({ user }: { user: User }) => {
        this.user = user;
        this.loading = false;
      },
      (error) => {
        console.log(error?.error?.detail ? error?.error?.detail : 'Error loading user');
      }
    );

    await this.loadInvoice();
    this.reviewDropdownClass$ = new BehaviorSubject('');
    this.setReviewStatusClass(this.invoice?.invoice_review_status);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async loadInvoice() {
    try {
      this.loading = true;

      const response = await this.api
        .sendRequest<BaseResponse<SearchReponseInvoice[]>>('GET', `/invoice/detailed`, { invoice_id: this.invoiceId })
        .toPromise();
      this.invoice = response.items[0];

      if (this.invoice.file.file_type === FileType.PDF) {
        let { items } = await this.api
          .sendRequest<BaseResponse<OcrMetadata[]>>('GET', `/invoice_ocr_metadata/invoice/${this.invoiceId}`)
          .toPromise();
        this.metaData = items[0];

        this.generateDownload();
      }
    } catch (err) {
      this.snackbar.showError('Error retrieving invoice');
    } finally {
      this.loading = false;
    }
  }

  getTableFilter(): (data: InvoiceDataItem, filter: string) => boolean {
    return (data, filter: string): boolean => {
      return data.title.toLowerCase().includes(filter.toLowerCase());
    };
  }

  fileLoadingProgress(_: any): void {
    this.loadingInvoice = false;
  }

  goBack() {
    window.close();
  }

  async generateDownload() {
    this.loadingInvoice = true;
    try {
      const pdf = await this.api.sendFileRequest(`/invoice/${this.invoiceId}/file/processed`).toPromise();
      const blob = new Blob([pdf], { type: 'application/pdf' });
      this.fileDownloadLink = URL.createObjectURL(blob);
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error generating file download link');
    }
  }

  downloadBlob() {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = this.fileDownloadLink;
    const date = this.invoice?.invoice_due_date ? this.datePipe.transform(Date.now(), 'yyyy-MM-dd') : '';
    a.download = `
      ${date}
      ${this.invoice.service_account.customer.customer_name}-
      ${this.invoice.service_account.utility.utility_name}-
      ${this.invoice.service_account.utility.utility_type}-processed`;
    a.click();
    a.remove();
  }

  async setReviewStatus(status: string): Promise<void> {
    try {
      await this.api
        .sendRequest(
          'PUT',
          `/invoice/${this.invoice.invoice_id}/status/`,
          {},
          { review_status: status, review_status_updated_by: this.user?.user_name }
        )
        .toPromise();
      const tempInvoice = this.invoice;
      tempInvoice.invoice_review_status_updated_by = this.user?.user_name;
      tempInvoice.invoice_review_status = ReviewStatus[status];
      this.invoice = tempInvoice;
      this.setReviewStatusClass(ReviewStatus[status]);
    } catch (err) {
      console.log(err);
      this.snackbar.showError(err?.error?.detail || 'Error updating the review status for this invoice');
    }
  }

  setReviewStatusClass(irs: ReviewStatus): void {
    switch (irs) {
      case ReviewStatus.NA:
        this.reviewDropdownClass$.next('text-light-grey');
        break;
      case ReviewStatus.InReview:
      case ReviewStatus.InReviewUser:
        this.reviewDropdownClass$.next('needs-review');
        break;
      default:
        this.reviewDropdownClass$.next('');
        break;
    }
  }
}
