import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddItemDialogComponent } from 'src/app/dialogs/add-item-dialog/add-item-dialog.component';
import { BaseResponse } from 'src/app/interfaces/base-response';
import { AddFormItem } from 'src/app/interfaces/dialog-form-data';
import { EPASubregion } from 'src/app/interfaces/epa-subregions';
import { SelectOption, SelectType } from 'src/app/interfaces/select-option';
import { Service, ServiceFilterOption, Utility } from 'src/app/interfaces/utility';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-utility-providers',
  templateUrl: './utility-providers.component.html',
  styleUrls: ['./utility-providers.component.scss'],
})
export class UtilityProvidersComponent implements OnInit {
  services: ServiceFilterOption[] = Object.keys(ServiceFilterOption).map((x) => ServiceFilterOption[x]);
  epaSubregions: EPASubregion[];
  service: string = ServiceFilterOption.all;
  name: string = '';
  loading = false;
  loadingReadyForProcessing = false;
  loadingNotReadyForProcessing = false;
  includeArchived: boolean = false;
  filterSelections: SelectOption[] = [
    {
      name: 'Service',
      slug: 'utility_type',
      value: '',
      options: Object.entries(Service).map(([label, value]) => ({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value,
      })),
      type: SelectType.typeAhead,
      allowPartial: false,
    },
    {
      name: 'Utility Name',
      slug: 'utility_name',
      options: [],
      value: '',
      type: SelectType.typeAhead,
      debounceInputs: true,
      allowPartial: true,
    },
  ];
  filterTable = (data, filter: string): boolean => {
    return data.utility_name.toLowerCase().includes(filter);
  };

  get isLoadingState() {
    return false; // this.loading || this.loadingReadyForProcessing || this.loadingNotReadyForProcessing;
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  constructor(
    private dialog: MatDialog,
    private api: ApiService,
    private snackbar: SnackbarService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setEpaSubregions();
  }

  filterData() {
    this.filterSelections = [...this.filterSelections];
  }

  async setEpaSubregions(): Promise<void> {
    try {
      this.loading = true;
      const result = await this.api.sendRequest<BaseResponse<EPASubregion[]>>('GET', `/epa_subregion/`).toPromise();
      if (!result || !result.items) {
        throw new Error('Unable to load EPA Subregions');
      }
      this.epaSubregions = result.items;
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error retrieving current EPA Subregions');
    } finally {
      this.loading = false;
    }
  }

  addUtilityDialog() {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '500px',

      data: {
        title: 'Create Utility Provider',
        addressType: 'Remit To',
        formItems: [
          {
            slug: 'utility_name',
            name: 'Name',
            required: true,
            type: AddFormItem.text,
          },
          {
            slug: 'utility_type',
            name: 'Service',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(Service).map((x) => {
              return { value: Service[x], display: Service[x] };
            }),
          },
          {
            slug: 'utility_is_third_party',
            name: 'Distribution Company',
            required: false,
            type: AddFormItem.checkbox,
          },
          {
            slug: 'utility_start_date',
            name: 'Utility Start Date',
            type: AddFormItem.date,
          },
          {
            slug: 'utility_end_date',
            name: 'Utility End Date',
            type: AddFormItem.date,
          },
          {
            slug: 'utility_address',
            name: 'Address',
            required: false,
            type: AddFormItem.address,
            currentValue: {
              street_address: 'utility_address_street_address',
              state: 'utility_address_state',
              city: 'utility_address_city',
              zip: 'utility_address_zip',
            },
          },
          {
            slug: 'utility_epa_subregion_id_fk',
            name: 'EPA Subregion',
            required: true,
            type: AddFormItem.select,
            selectItems: Object.keys(this.epaSubregions).map((x) => {
              return { value: this.epaSubregions[x].id, display: this.epaSubregions[x].name };
            }),
          },
        ],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action === 'success') {
        this.addUtility(result.data);
      }
    });
  }

  async addUtility(data: {
    utility_name: string;
    utility_type: string;
    utility_start_date: string;
    utility_end_date: string;
    utility_address_street_address: string;
    utility_address_state: string;
    utility_address_city: string;
    utility_address_zip: string;
    utility_is_third_party: boolean;
    utility_epa_subregion_id_fk: number | string;
  }) {
    try {
      this.loading = true;
      if (data.utility_epa_subregion_id_fk === '' || data.utility_epa_subregion_id_fk === null) {
        delete data.utility_epa_subregion_id_fk;
      }
      await this.api
        .sendRequest<Utility>('POST', `/utility/`, null, { ...data, utility_ready_for_processing: false })
        .toPromise();
      this.filterSelections.forEach((x) => (x.value = ''));
      this.filterSelections = [...this.filterSelections];
      this.snackbar.showInfo('Utility provider added');
    } catch (err) {
      console.log(err);
      this.snackbar.showError('Error adding utility');
    } finally {
      this.loading = false;
    }
  }

  changeArchived(includeArchived: boolean): void {
    this.includeArchived = includeArchived;
  }
}
