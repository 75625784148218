export enum AddFormItem {
  text = 'text',
  textarea = 'textarea',
  services = 'services',
  boolean = 'boolean',
  address = 'address',
  number = 'number',
  select = 'select',
  checkbox = 'checkbox',
  email = 'email',
  date = 'date',
}

export interface FormItem {
  slug: string;
  name: string;
  required: boolean;
  type: AddFormItem;
  selectItems?: SelectItem[];
  trueLabel?: string;
  falseLabel?: string;
  currentValue?: any;
}

export interface SelectItem {
  value: string;
  display: string;
}
